const datagenURL = "https://datagen-api.glassflow.xyz"

interface Generator {
	key: string
	value: string
}

interface LatestSchemaResponse {
	generator_type?: string
	generators?: Generator[]
	num_events?: number
	rps?: number
	statics?: Generator[]
}

interface Pipeline {
	pipeline_id: string
	pipeline_access_token: string
}

interface Generator {
	key: string
	value: string
}

interface GenerateCustom {
	pipeline: Pipeline
	generator: {
		generator_type: string
		generators: Generator[]
	}
}

interface GenerateCustomResponse {
	task_id?: string
	generator?: string
}

export async function fetchLatestSchema(
	pipeline_id: string,
): Promise<LatestSchemaResponse | null> {
	try {
		const response = await fetch(
			`${datagenURL}/tasks/latest/schema?pipeline_id=${pipeline_id}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			},
		)

		if (!response.ok) {
			throw new Error(
				`Error fetching latest schema: ${response.statusText}`,
			)
		}

		return await response.json()
	} catch (error) {
		console.error("Failed to fetch latest schema:", error)
		return null
	}
}

export async function postGenerateCustom(
	body: GenerateCustom,
): Promise<GenerateCustomResponse | null> {
	try {
		const response = await fetch(`${datagenURL}/tasks/generate/custom`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		})

		if (!response.ok) {
			throw new Error(
				`Error generating custom task: ${response.statusText}`,
			)
		}

		return await response.json()
	} catch (error) {
		console.error("Failed to generate custom task:", error)
		return null
	}
}
