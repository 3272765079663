import { observer } from "mobx-react"
import { types } from "mobx-state-tree"

import { RenamePipelineModal } from "./RenamePipeline/modal"
import { RenamePipelineModalModel } from "./RenamePipeline/modal.model"

import { UploadFunctionFileModal } from "./UploadFunctionFile/modal"
import { UploadFunctionFileModalModel } from "./UploadFunctionFile/modal.model"

import { DeletePipelineModal } from "./DeletePipeline/modal"
import { DeletePipelineModalModel } from "./DeletePipeline/modal.model"

import { CreateAccessTokenModal } from "./CreateAccessToken/modal"
import { CreateAccessTokenModalModel } from "./CreateAccessToken/modal.model"

import { DeleteAccessTokenModal } from "./DeleteAccessToken/modal"
import { DeleteAccessTokenModalModel } from "./DeleteAccessToken/modal.model"

import { RenameAccessTokenModal } from "./RenameAccessToken/modal"
import { RenameAccessTokenModalModel } from "./RenameAccessToken/modal.model"

import { CreateSpaceModal } from "./CreateSpace/modal"
import { CreateSpaceModalModel } from "./CreateSpace/modal.model"

import { CreatePipelineModal } from "./CreatePipeline/modal"
import { CreatePipelineModalModel } from "./CreatePipeline/modal.model"

import { RenameSpaceModal } from "./RenameSpace/modal"
import { RenameSpaceModalModel } from "./RenameSpace/modal.model"

import { DeleteSpaceModal } from "./DeleteSpace/modal"
import { DeleteSpaceModalModel } from "./DeleteSpace/modal.model"

import { EditEnvironmentVariablesModal } from "./EditEnvironmentVariables/modal"
import { EditEnvironmentVariablesModalModel } from "./EditEnvironmentVariables/modal.model"

import { DemoEditEnvironmentVariablesModal } from "./DemoEditEnvironmentVariables/modal"
import { DemoEditEnvironmentVariablesModalModel } from "./DemoEditEnvironmentVariables/modal.model"

import { CreatePipelineTransformModal } from "./CreatePipelineTransform/modal"
import { CreatePipelineTransformModalModel } from "./CreatePipelineTransform/modal.model"

import { CreatePipelineSourceModal } from "./CreatePipelineSource/modal"
import { CreatePipelineSourceModalModel } from "./CreatePipelineSource/modal.model"

import { CreatePipelineSinkModal } from "./CreatePipelineSink/modal"
import { CreatePipelineSinkModalModel } from "./CreatePipelineSink/modal.model"

import { OnboardingTipModal } from "./OnboardingTip/modal"
import { OnboardingTipModalModel } from "./OnboardingTip/modal.model"

import { DemoPipelineTransformModal } from "./DemoPipelineTransform/modal"
import { DemoPipelineTransformModalModel } from "./DemoPipelineTransform/modal.model"

import { DemoPipelineSinkModal } from "./DemoPipelineSink/modal"
import { DemoPipelineSinkModalModel } from "./DemoPipelineSink/modal.model"

import { DemoPipelineSourceModal } from "./DemoPipelineSource/modal"
import { DemoPipelineSourceModalModel } from "./DemoPipelineSource/modal.model"

import { PipelineTransformModal } from "./PipelineTransform/modal"
import { PipelineTransformModalModel } from "./PipelineTransform/modal.model"

import { PipelineSinkModal } from "./PipelineSink/modal"
import { PipelineSinkModalModel } from "./PipelineSink/modal.model"

import { PipelineSourceModal } from "./PipelineSource/modal"
import { PipelineSourceModalModel } from "./PipelineSource/modal.model"

const ModalsModel = types.model("ModalsStore", {
	renamePipeline: RenamePipelineModalModel,
	uploadFunctionFile: UploadFunctionFileModalModel,
	deletePipeline: DeletePipelineModalModel,
	createAccessToken: CreateAccessTokenModalModel,
	deleteAccessToken: DeleteAccessTokenModalModel,
	renameAccessToken: RenameAccessTokenModalModel,
	createSpace: CreateSpaceModalModel,
	createPipeline: CreatePipelineModalModel,
	renameSpace: RenameSpaceModalModel,
	deleteSpace: DeleteSpaceModalModel,
	editEnvironmentVariables: EditEnvironmentVariablesModalModel,
	demoEditEnvironmentVariables: DemoEditEnvironmentVariablesModalModel,
	demoPipelineTransform: DemoPipelineTransformModalModel,
	demoPipelineSink: DemoPipelineSinkModalModel,
	demoPipelineSource: DemoPipelineSourceModalModel,
	createPipelineSource: CreatePipelineSourceModalModel,
	createPipelineSink: CreatePipelineSinkModalModel,
	createPipelineTransform: CreatePipelineTransformModalModel,
	pipelineTransform: PipelineTransformModalModel,
	pipelineSink: PipelineSinkModalModel,
	pipelineSource: PipelineSourceModalModel,
	onboardingTip: OnboardingTipModalModel,
})

export const modalsStore = ModalsModel.create({
	renamePipeline: {},
	uploadFunctionFile: {},
	deletePipeline: {},
	createAccessToken: {},
	deleteAccessToken: {},
	renameAccessToken: {},
	createSpace: {},
	createPipeline: {},
	renameSpace: {},
	deleteSpace: {},
	editEnvironmentVariables: {},
	demoEditEnvironmentVariables: {},
	demoPipelineTransform: {},
	demoPipelineSink: {},
	demoPipelineSource: {},
	createPipelineSource: {},
	createPipelineSink: {},
	createPipelineTransform: {},
	pipelineTransform: {},
	pipelineSink: {},
	pipelineSource: {},
	onboardingTip: {},
})

export const Modals = observer(() => {
	return (
		<>
			<RenamePipelineModal />
			<UploadFunctionFileModal />
			<DeletePipelineModal />
			<CreateAccessTokenModal />
			<DeleteAccessTokenModal />
			<RenameAccessTokenModal />
			<CreateSpaceModal />
			<CreatePipelineModal />
			<RenameSpaceModal />
			<DeleteSpaceModal />
			<EditEnvironmentVariablesModal />
			<DemoEditEnvironmentVariablesModal />
			<DemoPipelineTransformModal />
			<DemoPipelineSinkModal />
			<DemoPipelineSourceModal />
			<CreatePipelineSourceModal />
			<CreatePipelineSinkModal />
			<CreatePipelineTransformModal />
			<PipelineTransformModal />
			<PipelineSinkModal />
			<PipelineSourceModal />
			<OnboardingTipModal />
		</>
	)
})
