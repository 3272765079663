import { Button, Icon, Skeleton, Text, View } from "reshaped"

import { AnimatePresence } from "framer-motion"
import { useState } from "preact/hooks"
import { Monitor } from "react-feather"
import { appStore } from "#app"
import { AnimatedVerticalPresence } from "../AnimatedVerticalPresence/component"
import css from "./useLargerScreen.module.css"

export const UseLargerScreen = () => {
	const [isEyesShown, showEyes] = useState(false)
	const [gifKey, setGifKey] = useState(Date.now())

	return (
		<View className={css.mobileOverlay}>
			<AnimatePresence>
				<AnimatedVerticalPresence key="UseLargerScreen">
					<View
						direction="column"
						gap={20}
						align="center"
						justify="center"
						padding={5}
					>
						{appStore.auth.isAuthenticated ? (
							<Text
								variant="title-1"
								align="center"
								className={css.welcome}
							>
								Welcome {appStore.auth.profile.name}!
							</Text>
						) : (
							<Skeleton width="100%" height={9} />
						)}
						<View direction="column" align="center" gap={3}>
							<Icon size={9} svg={<Monitor />} />
							<Text variant="body-1" align="center">
								For the best experience and
								<br />
								to access all features,
								<br /> please use GlassFlow on
								<br />
								your desktop or laptop
							</Text>
						</View>
						<View direction="column" align="center" gap={1}>
							<View height="28px">
								{isEyesShown && (
									<img
										src={`/icons/eyes.gif?${gifKey}`}
										height="28px"
										key={gifKey}
										alt="eyes"
									/>
								)}
							</View>
							<Button
								disabled={isEyesShown}
								className={css.button}
								onClick={() => {
									setGifKey(Date.now())
									setTimeout(() => showEyes(true), 100)
									setTimeout(() => showEyes(false), 2000)
								}}
							>
								Okay, I will check it later
							</Button>
						</View>
					</View>
				</AnimatedVerticalPresence>
			</AnimatePresence>
		</View>
	)
}
