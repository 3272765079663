import { getLinkProps, router } from "#router"
import { PipelineCreateType } from "./enums"

export const getPipelineCreateType = () => {
	if (getLinkProps(router.PipelinesCreateEmailEncryption()).active) {
		return PipelineCreateType.EmailEncryption
	} else {
		return PipelineCreateType.Blank
	}
}

type FunctionRegistry = Map<string, () => void>

const createPipelineFunctionRegistry: FunctionRegistry = new Map()

export const registerCreatePipelineFunction = (
	id: string,
	fn: () => void,
): void => {
	createPipelineFunctionRegistry.set(id, fn)
}

export const getCreatePipelineFunction = (id: string): (() => void) => {
	return createPipelineFunctionRegistry.get(id) || (() => {})
}
