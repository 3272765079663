import * as Fathom from "fathom-client"
import {
	type Instance,
	applySnapshot,
	cast,
	flow,
	getSnapshot,
	types,
} from "mobx-state-tree"

import {
	AccessTokenModel,
	type PipelineModel,
	SpaceModel,
	api,
	postGenerateCustom,
} from "#api"
import { appId, appStore, isProduction } from "#app"
import { metricsApi } from "#metrics"
import { router } from "#router"
import { PipelineCreateType } from "../../enums"

export const HandlerTemplate = `from Crypto.Cipher import AES
from Crypto.Util.Padding import pad
import base64
import os


# Function to encrypt email address
def handler(data, log):
    key = os.getenv("AES_KEY").encode('utf-8')

    email = data['email']

    # AES key must be either 16, 24, or 32 bytes
    cipher = AES.new(key, AES.MODE_CBC)  # CBC mode with a random IV
    iv = cipher.iv  # Get the Initialization Vector (IV)

    # Pad the email to be a multiple of 16 bytes
    padded_email = pad(email.encode(), AES.block_size)

    # Encrypt the padded email
    encrypted_email = cipher.encrypt(padded_email)

    # Return IV + encrypted email encoded in base64 (to make it easier to store or send)
    encrypted_email = base64.b64encode(iv + encrypted_email).decode()
    data['email_encrypted'] = encrypted_email
    return data
`

export const RequirementsTemplate = "pycryptodome==3.20.0"

export const DefaultGenerators = [
	{ key: "email", value: "email" },
	{ key: "user_name", value: "name" },
]

const EnvironmentVariable = {
	name: "AES_KEY",
	value: "0363c1bbfa467c65f12ea59611d75eb9",
}

export const CreateEmailEncryptionPipelinePageModel = types
	.model("CreateEmailEncryptionPipelinePage", {
		isCreating: types.optional(types.boolean, false),
		createdPipelineId: types.maybe(types.string),
		accessToken: types.maybe(AccessTokenModel),
		spaces: types.array(SpaceModel),
		onboardingTips: types.optional(
			types
				.model("OnboardingTips", {
					welcome: types.optional(types.boolean, false),
					deployPipeline: types.optional(types.boolean, false),
					source: types.optional(types.boolean, false),
					sink: types.optional(types.boolean, false),
				})
				.actions((self) => ({
					setOnboardingTip(key: keyof typeof self, value: boolean) {
						const snapshot = getSnapshot(self)
						applySnapshot(self, { ...snapshot, [key]: value })
					},
				})),
			{},
		),
		setupForm: types.optional(
			types
				.model("SetupForm", {
					name: types.optional(
						types.model("name", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					space: types.optional(
						types.model("space", {
							value: types.optional(types.string, ""),
						}),
						{},
					),
				})
				.actions((self) => {
					return {
						reset() {
							self.name.value = "E-mail Encryption"
							self.name.hasError = false
							self.space.value = ""
						},
						validate() {
							if (
								self.name.value === "" ||
								self.name.value === undefined
							) {
								self.name.hasError = true
								self.name.errorMessage =
									"Your pipeline needs a name"
							} else {
								self.name.hasError = false
							}
						},
						setName(name: string) {
							self.name.value = name
							self.name.hasError = false
						},
					}
				})
				.views((self) => {
					return {
						get hasError() {
							return self.name.hasError
						},
					}
				}),
			{},
		),
		sourceForm: types.optional(
			types
				.model("SourceForm", {
					connector: types.optional(
						types.model("SDK", {
							type: types.literal("sdk"),
							meta: types.optional(types.model(), {}),
						}),
						{
							type: "sdk",
							meta: {},
						},
					),
				})
				.actions((self) => {
					return {
						reset() {
							self.connector = cast({ type: "sdk", meta: {} })
						},
					}
				}),
			{},
		),
		transformerForm: types.optional(
			types
				.model("TransformerForm", {
					requirements: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					handler: types.optional(
						types.model("file", {
							value: types.optional(types.string, ""),
							hasError: types.optional(types.boolean, false),
							errorMessage: types.optional(types.string, ""),
						}),
						{},
					),
					environmentVariables: types.array(
						types.model({
							name: types.string,
							value: types.string,
						}),
					),
				})
				.actions((self) => {
					return {
						reset() {
							self.requirements.value = RequirementsTemplate
							self.requirements.hasError = false
							self.handler.value = HandlerTemplate
							self.handler.hasError = false
							self.environmentVariables = cast([
								EnvironmentVariable,
							])
						},
					}
				}),
			{},
		),
		sinkForm: types.optional(
			types
				.model("SinkForm", {
					connector: types.optional(
						types.model("SDK", {
							type: types.literal("sdk"),
							meta: types.optional(types.model(), {}),
						}),
						{
							type: "sdk",
							meta: {},
						},
					),
				})
				.actions((self) => {
					return {
						reset() {
							self.connector = cast({ type: "sdk", meta: {} })
						},
					}
				}),
			{},
		),
		editorSettings: types.optional(
			types
				.model({
					showInvisibles: types.optional(types.boolean, false),
					softWrap: types.optional(types.boolean, true),
				})
				.actions((self) => {
					return {
						setShowInvisibles(value: boolean) {
							self.showInvisibles = value
						},

						setSoftWrap(value: boolean) {
							self.softWrap = value
						},
					}
				}),
			{},
		),
		error: types.maybe(types.frozen()),
		isFetchingSpaces: types.optional(types.boolean, false),
	})
	.actions((self) => {
		const resetPage = () => {
			self.createdPipelineId = undefined
			self.isCreating = false
			self.accessToken = undefined
			self.setupForm.reset()
			self.sourceForm.reset()
			self.sinkForm.reset()
			self.transformerForm.reset()
			self.editorSettings.setShowInvisibles(false)
			self.editorSettings.setSoftWrap(true)
			self.error = undefined
			self.onboardingTips.welcome = false
			self.onboardingTips.deployPipeline = false
			self.onboardingTips.sink = false
			self.onboardingTips.source = false

			Fathom.trackEvent("Pipeline:Create:Started")
		}

		const getSpaces = flow(function* () {
			self.isFetchingSpaces = true

			self.spaces = cast([
				{
					id: "",
					name: "Loading Spaces...",
					created_at: "",
					permission: "",
				},
			])
			self.setupForm.space.value = self.spaces[0].id

			const spaces: Awaited<ReturnType<typeof api.listSpaces>> =
				yield api.listSpaces({
					queries: { page_size: 100 },
				})

			self.spaces = cast(spaces.spaces)
			self.setupForm.space.value = self.spaces[0].id

			self.isFetchingSpaces = false
		})

		const submit = flow(function* () {
			self.setupForm.validate()

			if (!self.setupForm.hasError) {
				if (appStore.auth.isAuthenticated && isProduction()) {
					metricsApi.postMetricEvents(
						[
							{
								name: "pipeline_creation_submitted",
								created_at: new Date().toISOString(),
								data: {
									user_id: appStore.auth.profile.id,
									email: appStore.auth.profile.email,
									timestamp: new Date().toISOString(),
								},
								metadata: {
									source: appId,
								},
							},
						],
						{},
					)
				}

				let pipeline: Awaited<ReturnType<typeof createPipeline>> =
					yield createPipeline()

				if (pipeline !== false) {
					router.push("DemoPipelinesDetailGlassFlowSink", {
						pipelineId: pipeline.id,
					})
				}
			}
		})

		const createPipeline = flow<
			Promise<false | Instance<typeof PipelineModel>>,
			[]
		>(function* () {
			self.setupForm.validate()

			if (self.setupForm.hasError) {
				return false
			}

			try {
				self.isCreating = true

				let pipeline = yield api.createPipeline({
					name: self.setupForm.name.value,
					space_id: self.setupForm.space.value,
					transformation_function: self.transformerForm.handler.value,
					requirements_txt: self.transformerForm.requirements.value,
					metadata: {
						sourceConnector: {
							type: self.sourceForm.connector.type,
						},
						type: PipelineCreateType.EmailEncryption,
					},
					source_connector: null,
					sink_connector: null,
					environments: getSnapshot(
						self.transformerForm.environmentVariables,
					).filter(({ name, value }) => {
						if (name === "" || value === "") {
							return false
						} else {
							return true
						}
					}),
				})

				postGenerateCustom({
					pipeline: {
						pipeline_id: pipeline.id,
						pipeline_access_token: pipeline.access_token,
					},
					generator: {
						generator_type: "custom",
						generators: DefaultGenerators,
					},
				})

				self.createdPipelineId = pipeline.id

				let tokens: Awaited<ReturnType<typeof api.listAccessTokens>> =
					yield api.listAccessTokens({
						queries: {
							page_size: 1000,
						},
						params: {
							pipeline_id: pipeline.id,
						},
					})

				self.accessToken = tokens.access_tokens[0]

				Fathom.trackEvent("Pipeline:Create Success")

				return pipeline
			} catch (error) {
				self.isCreating = false
				self.error = error

				return false
			}
		})

		return {
			submit,
			getSpaces,
			resetPage,
			createPipeline,
		}
	})

export const pipelineEmailEncryptionCreatePageStore =
	CreateEmailEncryptionPipelinePageModel.create()
