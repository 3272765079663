import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"

let updateCallback: (
	space: Awaited<ReturnType<typeof api.createSpace>>,
) => Promise<void>

export const CreateSpaceModalModel = types
	.model("CreateSpaceModal", {
		isOpen: types.optional(types.boolean, false),
		isCreating: types.optional(types.boolean, false),
		form: types.optional(
			types
				.model("Form", {
					name: types.optional(types.string, ""),
					hasError: types.optional(types.boolean, false),
					errorMessage: types.optional(types.string, ""),
				})
				.actions((self) => {
					const validate = () => {
						if (self.name === "") {
							self.hasError = true
							self.errorMessage = "This field is required"
						} else {
							self.hasError = false
						}
					}

					const setName = (name: string) => {
						self.name = name
					}

					return { validate, setName }
				}),
			{},
		),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			callback: (
				space: Awaited<ReturnType<typeof api.createSpace>>,
			) => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("Space:Create Started")
			updateCallback = callback

			self.isOpen = true
			self.form.name = ""
			self.form.hasError = false
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const createSpace = flow(function* () {
			self.form.validate()

			if (self.form.hasError) {
				return false
			}

			try {
				self.isCreating = true

				const [space] = yield Promise.all([
					api.createSpace({
						name: self.form.name,
					}),
					new Promise((resolve) => setTimeout(resolve, 500)),
				])

				Fathom.trackEvent("Space:Create Success")

				try {
					yield updateCallback(space)
				} catch (error) {}

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isCreating = false

				return true
			} catch (error) {
				self.isCreating = false
				self.error = error

				return false
			}
		})

		return { openModal, closeModal, createSpace }
	})
