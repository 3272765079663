import { easeInOut, m, useReducedMotion } from "framer-motion"
import { observer } from "mobx-react"

export const AnimatedHorizontalPresence: React.FunctionComponent<{
	key: string
	config?: { delay?: number }
}> = observer((props) => {
	const prefersReducedMotion = useReducedMotion()

	let xOffset = 20

	if (prefersReducedMotion) {
		xOffset = 0
	}

	return (
		<m.div
			key={props.key}
			initial={{ opacity: 0, x: -xOffset }}
			animate={{
				opacity: 1,
				x: 0,
			}}
			transition={{
				ease: easeInOut,
				duration: 0.5,
				delay: props.config?.delay,
			}}
			exit={{
				opacity: 0,
				x: xOffset,
			}}
			style={
				// Override framer-motions "magic" which breaks the Pipeline Details Page > Logs Fullscreen
				{ willChange: "auto" }
			}
		>
			{props.children}
		</m.div>
	)
})
