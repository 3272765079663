import * as Fathom from "fathom-client"
import { type Instance, flow, types } from "mobx-state-tree"

import { type AccessTokenModel, api } from "#api"

let updateCallback: () => Promise<void>

export const RenameAccessTokenModalModel = types
	.model("RenameAccessTokenModal", {
		isOpen: types.optional(types.boolean, false),
		isRenaming: types.optional(types.boolean, false),
		originalTokenName: types.optional(types.string, ""),
		tokenId: types.optional(types.string, ""),
		pipelineId: types.optional(types.string, ""),
		form: types.optional(
			types
				.model("Form", {
					name: types.optional(types.string, ""),
					hasError: types.optional(types.boolean, false),
					errorMessage: types.optional(types.string, ""),
				})
				.actions((self) => {
					return {
						validate() {
							self.hasError = false

							if (self.name === "") {
								self.hasError = true
								self.errorMessage = "This field is required"
							}
						},
						setName(name: string) {
							self.name = name
							self.hasError = false
						},
					}
				}),
			{},
		),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			token: Instance<typeof AccessTokenModel>,
			pipelineId: string,
			callback: () => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("AccessToken:Rename Started")
			updateCallback = callback

			self.originalTokenName = token.name
			self.tokenId = token.id
			self.pipelineId = pipelineId

			self.isOpen = true
			self.form.name = token.name
			self.form.hasError = false
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const renameToken = flow(function* () {
			self.form.validate()

			if (self.form.hasError) {
				return false
			}

			try {
				self.isRenaming = true

				yield Promise.all([
					new Promise((resolve) => setTimeout(resolve, 500)),
					api.updateAccessToken(
						{
							name: self.form.name,
						},
						{
							params: {
								token_id: self.tokenId,
								pipeline_id: self.pipelineId,
							},
						},
					),
				])

				Fathom.trackEvent("AccessToken:Rename Success")

				try {
					yield updateCallback()
				} catch (error) {}

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isRenaming = false

				return true
			} catch (error) {
				self.isRenaming = false
				self.error = error

				return false
			}
		})

		return {
			openModal,
			closeModal,
			renameToken,
		}
	})
