import { observer } from "mobx-react"
import { Copy, RefreshCw, X } from "react-feather"
import {
	Button,
	FormControl,
	Skeleton,
	Text,
	TextField,
	View,
	useToast,
} from "reshaped"

import { appStore } from "#app"

import { profilePageStore } from "./page.model"
import css from "./page.module.css"

export const Profile = observer(() => {
	const toast = useToast()

	return (
		<View gap={3} paddingTop={4}>
			<View paddingBottom={1}>
				<Text variant="title-3">Profile</Text>
			</View>
			<Text variant="title-6">User</Text>

			<View direction="row" gap={6} wrap>
				<View.Item columns={6}>
					<FormControl>
						<FormControl.Label>Name</FormControl.Label>
						{appStore.auth.isAuthenticated ? (
							<TextField
								name="name"
								disabled
								value={appStore.auth.profile.name}
							/>
						) : (
							<Skeleton width="100%" height={9} />
						)}
					</FormControl>
				</View.Item>

				<View.Item columns={6}>
					<FormControl>
						<FormControl.Label>OAuth Provider</FormControl.Label>
						{appStore.auth.isAuthenticated ? (
							<TextField
								className={css.monospaceInput}
								name="oauth-provider"
								disabled
								value={appStore.auth.profile.provider}
							/>
						) : (
							<Skeleton width="100%" height={9} />
						)}
					</FormControl>
				</View.Item>

				<View.Item columns={6}>
					<FormControl>
						<FormControl.Label>Email</FormControl.Label>
						{appStore.auth.isAuthenticated ? (
							<TextField
								name="email"
								disabled
								value={appStore.auth.profile?.email ?? ""}
								endSlot={
									<Button
										size="small"
										variant="ghost"
										icon={Copy}
										onClick={async () => {
											if (appStore.auth.isAuthenticated) {
												await navigator.clipboard.writeText(
													appStore.auth.profile.email,
												)

												const id = toast.show({
													color: "neutral",
													icon: Copy,
													title: "Copied User Email",
													actionsSlot: (
														<Button
															variant="ghost"
															icon={X}
															onClick={() =>
																toast.hide(id)
															}
														/>
													),

													timeout: "short",
													position: "bottom-end",
												})
											}
										}}
									/>
								}
							/>
						) : (
							<Skeleton width="100%" height={9} />
						)}
					</FormControl>
				</View.Item>

				<View.Item columns={6}>
					<FormControl>
						<FormControl.Label>
							Personal Access Token
						</FormControl.Label>
						{profilePageStore.personalAccessToken ? (
							<TextField
								name="pmt"
								disabled
								value={profilePageStore.personalAccessToken}
								endSlot={
									<Button
										size="small"
										variant="ghost"
										icon={Copy}
										onClick={async () => {
											if (
												profilePageStore.personalAccessToken
											) {
												await navigator.clipboard.writeText(
													profilePageStore.personalAccessToken,
												)

												const id = toast.show({
													color: "neutral",
													icon: Copy,
													title: "Copied Personal Access Token",
													actionsSlot: (
														<Button
															variant="ghost"
															icon={X}
															onClick={() =>
																toast.hide(id)
															}
														/>
													),

													timeout: "short",
													position: "bottom-end",
												})
											}
										}}
									/>
								}
							/>
						) : (
							<Skeleton width="100%" height={9} />
						)}
					</FormControl>
				</View.Item>
			</View>

			<View paddingTop={6}>
				<Text variant="title-6">Home Organisation</Text>
			</View>

			<View gap={6} direction="row">
				<View.Item columns={6}>
					<FormControl>
						<FormControl.Label>Name</FormControl.Label>
						{appStore.auth.isAuthenticated ? (
							<TextField
								name="name"
								disabled
								value={
									appStore.auth.profile?.home_organization
										.name ?? ""
								}
							/>
						) : (
							<Skeleton width="100%" height={9} />
						)}
					</FormControl>
				</View.Item>
				<View.Item columns={6}>
					<FormControl>
						<FormControl.Label>ID</FormControl.Label>
						{appStore.auth.isAuthenticated ? (
							<TextField
								className={css.monospaceInput}
								name="id"
								disabled
								value={
									appStore.auth.profile?.home_organization
										.id ?? ""
								}
								endSlot={
									<Button
										size="small"
										variant="ghost"
										icon={Copy}
										onClick={async () => {
											if (appStore.auth.isAuthenticated) {
												await navigator.clipboard.writeText(
													appStore.auth.profile
														.home_organization.id,
												)

												const id = toast.show({
													color: "neutral",
													icon: Copy,
													title: "Copied Home Organisation ID",
													actionsSlot: (
														<Button
															variant="ghost"
															icon={X}
															onClick={() =>
																toast.hide(id)
															}
														/>
													),

													timeout: "short",
													position: "bottom-end",
												})
											}
										}}
									/>
								}
							/>
						) : (
							<Skeleton width="100%" height={9} />
						)}
					</FormControl>
				</View.Item>
			</View>
		</View>
	)
})
