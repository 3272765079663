import { easeInOut, m } from "framer-motion"
import { observer } from "mobx-react"
import { Code, Coffee, Lock } from "react-feather"
import { Button, Hidden, Text, View } from "reshaped"
import Avira from "../../../public/icons/avira.svg?react"
import N26 from "../../../public/icons/n26.svg?react"
import Rocket from "../../../public/icons/rocket.svg?react"
import Sumup from "../../../public/icons/sumup.svg?react"
import Uber from "../../../public/icons/uber.svg?react"

import { unauthenticatedPageStore } from "./page.model"
import css from "./page.module.css"

import Logo from "./logo.svg?react"

export const Unauthenticated = observer(() => {
	return (
		<View justify="center" align="center" height="100dvh" width="100dvw">
			<View direction="row" width="100dvw" align="center">
				<View.Item columns={{ s: 12, m: 6 }}>
					<View
						gap={5}
						align="center"
						paddingBottom={10}
						paddingStart={6}
						paddingEnd={6}
						height="100dvh"
						justify="center"
						className={css.leftHalf}
					>
						<View align="center">
							<Logo width="275px" class={css.logo} />
							<Text variant="title-1" className={css.welcome}>
								Welcome!
							</Text>
							<Text
								variant="body-1"
								color="neutral"
								align="center"
							>
								Sign up for free, no credit card required
							</Text>
						</View>
						<View
							align="stretch"
							direction="row"
							gap={4}
							width="100%"
							maxWidth="336px"
						>
							<View grow>
								<Button
									className={css.signUp}
									variant="solid"
									color="neutral"
									size="large"
									fullWidth
									onClick={() =>
										unauthenticatedPageStore.signUp()
									}
								>
									Sign up
								</Button>
							</View>
							<View grow>
								<Button
									className={css.signIn}
									variant="solid"
									color="primary"
									size="large"
									fullWidth
									onClick={() =>
										unauthenticatedPageStore.login()
									}
								>
									Sign in
								</Button>
							</View>
						</View>
					</View>
				</View.Item>
				<Hidden hide={{ s: true, m: false }}>
					<View.Item columns={6} gapBefore={5}>
						<View
							gap={7}
							paddingBottom={10}
							paddingStart={4}
							paddingEnd={4}
							height="100dvh"
							justify="center"
							align="center"
							className={css.rightHalf}
						>
							<m.div
								initial={{ opacity: 0, x: 20 }}
								animate={{
									opacity: 1,
									x: 0,
								}}
								transition={{
									ease: easeInOut,
									duration: 0.4,
									delay: 0.2,
								}}
							>
								<View maxWidth={108} className={css.card}>
									<View
										direction="column"
										gap={4}
										padding={6}
									>
										<View
											direction="row"
											gap={4}
											align="center"
										>
											<Coffee />
											<Text
												variant="body-3"
												weight="bold"
											>
												Time to Enjoy Building Data
												Pipelines!
											</Text>
										</View>
										<Text
											variant="caption-1"
											color="neutral-faded"
										>
											Forget about complex infrastructure
											setups. Simply focus on building
											your data pipelines with Python
											while we handle the rest.
										</Text>
									</View>
								</View>
							</m.div>
							<m.div
								initial={{ opacity: 0, x: 20 }}
								animate={{
									opacity: 1,
									x: 0,
								}}
								transition={{
									ease: easeInOut,
									duration: 0.4,
									delay: 0.3,
								}}
							>
								<View maxWidth={108} className={css.card}>
									<View
										direction="column"
										gap={4}
										padding={6}
									>
										<View
											direction="row"
											gap={4}
											align="center"
										>
											<Lock />
											<Text
												variant="body-3"
												weight="bold"
											>
												Ensuring Maximum Security
											</Text>
										</View>
										<Text
											variant="caption-1"
											color="neutral-faded"
										>
											Your data is our priority. All data
											is encrypted at rest and in transit.
										</Text>
									</View>
								</View>
							</m.div>
							<m.div
								initial={{ opacity: 0, x: 20 }}
								animate={{
									opacity: 1,
									x: 0,
								}}
								transition={{
									ease: easeInOut,
									duration: 0.4,
									delay: 0.4,
								}}
							>
								<View maxWidth={108} className={css.card}>
									<View
										direction="column"
										gap={4}
										padding={6}
									>
										<View
											direction="row"
											gap={4}
											align="center"
										>
											<Code />
											<Text
												variant="body-3"
												weight="bold"
											>
												Built by Data People From
											</Text>
										</View>
										<View
											direction="row"
											align="center"
											justify="space-between"
											gap={3}
										>
											<Avira height="17px" />
											<N26 height="19px" />
											<Rocket height="10px" />
											<Sumup height="18px" />
											<Uber height="13px" />
										</View>
									</View>
								</View>
							</m.div>
						</View>
					</View.Item>
				</Hidden>
			</View>
		</View>
	)
})
