import { observer } from "mobx-react"
import { Dismissible, Modal } from "reshaped"
import css from "./modal.module.css"

import { useLocation } from "@swan-io/chicane"
import classNames from "classnames"
import { useEffect, useRef } from "preact/hooks"
import { modalsStore } from "#modals"
import { useOutsideClick } from "../../hooks/useOutsideClick"
import { getCreatePipelineFunction } from "../../utils"

export const OnboardingTipModal = observer(() => {
	const modalStore = modalsStore.onboardingTip
	const location = useLocation()
	const ref = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (
			!location.raw.path.includes("demo/pipelines/") ||
			!location.raw.path.includes("pipelines/create/email-encryption")
		) {
			modalStore.closeModal()
		}
	}, [location.raw.path, modalStore.closeModal])

	useEffect(() => {
		if (modalsStore.onboardingTip.isOpen) {
			if (
				!modalStore.isTransparentOverlay ||
				modalsStore.demoPipelineTransform.isOpen ||
				modalsStore.createPipelineTransform.isOpen ||
				modalsStore.createPipelineSource.isOpen ||
				modalsStore.createPipelineSink.isOpen
			) {
				setTimeout(() => {
					document.body.style.overflow = "hidden"
				}, 500)
			} else {
				setTimeout(() => {
					document.body.style.overflow = ""
				}, 500)
			}
		}
	}, [modalStore.isTransparentOverlay])

	useEffect(() => {
		if (modalsStore.onboardingTip.isOpen && modalStore.highlightId) {
			const highlightElement: HTMLElement | null =
				document.getElementById(modalStore.highlightId)

			if (highlightElement) {
				highlightElement.style.zIndex = "1000"
			}

			return () => {
				if (highlightElement) {
					highlightElement.style.zIndex = "auto"
				}
			}
		}
	}, [modalStore.highlightId])

	const onClose = () => {
		modalStore.closeModal()
		getCreatePipelineFunction(modalStore.closeFunctionId)()
		if (modalStore.highlightId) {
			const highlightElement: HTMLElement | null =
				document.getElementById(modalStore.highlightId)

			if (highlightElement) {
				setTimeout(() => {
					highlightElement.style.zIndex = "auto"
				}, 250)
			}
		}
	}

	useOutsideClick(ref, onClose)

	return (
		<Modal
			attributes={{ ref: ref }}
			className={classNames(css.modal, {
				[css.bottom]: modalStore.isBottomAligned,
			})}
			active={modalStore.isOpen}
			transparentOverlay={modalStore.isTransparentOverlay}
			overlayClassName={
				modalStore.isTransparentOverlay ? css.overlay0 : css.overlay25
			}
			size={modalStore.width || "540px"}
			onClose={onClose}
		>
			<Dismissible onClose={onClose} closeAriaLabel="Close modal">
				{/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
				<div dangerouslySetInnerHTML={{ __html: modalStore.text }} />
			</Dismissible>
		</Modal>
	)
})
