import { Button, Text, View } from "reshaped"
import type { Question } from "../page"
import { Header } from "../page"
import css from "../page.module.css"

type ExpandableTabProps = {
	step: Question
	setActiveQuestionIndex: (index: number) => void
	saveAnswers: (index: number, answer: string) => void
}
export const YourExperience: React.FunctionComponent<ExpandableTabProps> = (
	props,
) => {
	return (
		<View
			borderRadius="large"
			borderColor={{ s: undefined, m: "neutral" }}
			padding={8}
			width="100vw"
			maxWidth="900px"
		>
			<Header />
			<View>
				<View paddingBottom={2}>
					<Text
						variant={{ s: "featured-2", m: "featured-1" }}
						weight="medium"
					>
						{props.step.question}
					</Text>
				</View>
				<View
					paddingTop={12}
					direction={{ s: "column", m: "row" }}
					align={{ s: "start", m: "center" }}
					gap={4}
					maxWidth="650px"
				>
					{props.step.answers.map((answer) => (
						<Button
							className={css.answer}
							key={answer}
							color="neutral"
							rounded
							variant="faded"
							onClick={() => {
								props.setActiveQuestionIndex(3)
								props.saveAnswers(2, answer)
							}}
						>
							{answer}
						</Button>
					))}
				</View>
			</View>
		</View>
	)
}
