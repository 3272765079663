import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import {
	ActionBar,
	Alert,
	Button,
	FormControl,
	Hidden,
	Modal,
	TextField,
	View,
	useToast,
} from "reshaped"

import { modalsStore } from "#modals"

export const RenamePipelineModal = observer(() => {
	const renamePipelineModalStore = modalsStore.renamePipeline

	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const renamePipeline = async () => {
		if (await renamePipelineModalStore.renamePipeline()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: "Successfully renamed Pipeline.",
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (renamePipelineModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={renamePipelineModalStore.isOpen}
			padding={0}
			size="500px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Rename Pipeline</Modal.Title>

				<form
					onSubmit={(event) => {
						event.stopImmediatePropagation()
						event.preventDefault()
						renamePipeline()
					}}
				>
					<View gap={3}>
						<FormControl>
							<FormControl.Label>Current Name</FormControl.Label>
							<TextField
								name="currentName"
								value={
									renamePipelineModalStore.currentPipelineName
								}
								disabled
							/>
						</FormControl>

						<FormControl
							hasError={renamePipelineModalStore.form.hasError}
						>
							<FormControl.Label>New Name</FormControl.Label>
							<TextField
								name="newName"
								value={renamePipelineModalStore.form.name}
								onChange={(event) => {
									renamePipelineModalStore.form.setName(
										event.value,
									)
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{renamePipelineModalStore.form.errorMessage}
							</FormControl.Error>
						</FormControl>
					</View>

					<Hidden hide>
						<button type="submit" />
					</Hidden>
				</form>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						disabled={renamePipelineModalStore.isRenaming}
						color="neutral"
						onClick={() => {
							renamePipelineModalStore.closeModal()
						}}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						loading={renamePipelineModalStore.isRenaming}
						onClick={() => renamePipeline()}
					>
						Rename Pipeline
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
