import { types } from "mobx-state-tree"

export const CreatePipelineSourceModalModel = types
	.model("CreatePipelineSourceModal", {
		isOpen: types.optional(types.boolean, false),
	})
	.actions((self) => {
		return {
			openModal() {
				self.isOpen = true
			},
			closeModal() {
				self.isOpen = false
			},
		}
	})
