import { replaceUnsafe } from "@swan-io/chicane"
import classNames from "classnames"
import { AnimatePresence } from "framer-motion"
import { observer } from "mobx-react"
import { useEffect, useState } from "preact/hooks"
import { Button, Hidden, View } from "reshaped"
import { router } from "#router"
import { AnimatedVerticalRevertedPresence } from "../../components/AnimatedVerticalPresence/component"
import { questionnaireStore } from "./page.model"
import css from "./page.module.css"
import { ThankYou } from "./steps/ThankYou"
import { UseCase } from "./steps/useCase"
import { YourExperience } from "./steps/yourExperience"
import { YourRole } from "./steps/yourRole"

export type Question = {
	question: string
	answers: string[]
	selectedAnswer: string | null
}

export const Header = () => {
	const { questions, activeQuestionIndex, saveAnswers } = questionnaireStore

	const skipAnswers = () => {
		saveAnswers()
		replaceUnsafe(router.Home())
	}

	return (
		<View align="center" direction="row" justify="space-between" gap={4}>
			<View
				paddingBottom={{ s: 3, m: 0 }}
				direction="row"
				gap={{ s: 1, m: 0.5 }}
			>
				{questions.map((step, index) => (
					<View
						className={classNames(css.step, {
							[css.activeStep]: activeQuestionIndex >= index,
						})}
						key={step.question}
					/>
				))}
			</View>
			<Hidden hide={{ s: true, m: false }}>
				<Button
					color="neutral"
					variant="outline"
					onClick={() => skipAnswers()}
					className={css.skip}
				>
					Skip
				</Button>
			</Hidden>
		</View>
	)
}

export const Questions = observer(() => {
	const {
		questions,
		activeQuestionIndex,
		isSecondQuestionAnswersShown,
		setActiveQuestionIndex,
		showSecondQuestionAnswers,
	} = questionnaireStore

	const [transitioning, setTransitioning] = useState(false)

	const handleNextStep = (nextIndex: number) => {
		setTransitioning(true)
		setTimeout(() => {
			setActiveQuestionIndex(nextIndex)
			setTransitioning(false)
		}, 500)
	}

	useEffect(() => {
		if (activeQuestionIndex === questions.length) {
			setTimeout(() => replaceUnsafe(router.Home()), 2000)
		}
	}, [activeQuestionIndex, questions])

	const selectAnswer = (index: number, answer: string) => {
		const question = questions[index]
		question.selectAnswer(answer)
	}
	const saveAnswers = (index: number, answer: string) => {
		selectAnswer(index, answer)
		questionnaireStore.saveAnswers()
	}

	return (
		<View
			direction="column"
			paddingTop={{
				s:
					!transitioning && activeQuestionIndex > questions.length - 1
						? 0
						: 22,
				m: 0,
			}}
			justify={{
				s:
					!transitioning && activeQuestionIndex > questions.length - 1
						? "center"
						: "start",
				m: "center",
			}}
			align="center"
			height="100vh"
			maxHeight="100vh"
		>
			<AnimatePresence>
				{!transitioning && activeQuestionIndex === 0 && (
					<AnimatedVerticalRevertedPresence key="YourRole">
						<YourRole
							step={questions[0]}
							setActiveQuestionIndex={handleNextStep}
							selectAnswer={selectAnswer}
						/>
					</AnimatedVerticalRevertedPresence>
				)}
				{!transitioning && activeQuestionIndex === 1 && (
					<AnimatedVerticalRevertedPresence key="UseCase">
						<UseCase
							step={questions[1]}
							isSecondQuestionAnswersShown={
								isSecondQuestionAnswersShown
							}
							showSecondQuestionAnswers={
								showSecondQuestionAnswers
							}
							setActiveQuestionIndex={handleNextStep}
							selectAnswer={selectAnswer}
						/>
					</AnimatedVerticalRevertedPresence>
				)}
				{!transitioning && activeQuestionIndex === 2 && (
					<AnimatedVerticalRevertedPresence key="YourExperience">
						<YourExperience
							step={questions[2]}
							setActiveQuestionIndex={handleNextStep}
							saveAnswers={saveAnswers}
						/>
					</AnimatedVerticalRevertedPresence>
				)}
				{!transitioning &&
					activeQuestionIndex > questions.length - 1 && (
						<AnimatedVerticalRevertedPresence key="ThankYou">
							<ThankYou />
						</AnimatedVerticalRevertedPresence>
					)}
			</AnimatePresence>
		</View>
	)
})
