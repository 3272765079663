import { observer } from "mobx-react"
import { GitHub, Info, Trash2 } from "react-feather"
import {
	Button,
	FormControl,
	Icon,
	Link,
	RadioGroup,
	Select,
	Text,
	TextField,
	View,
} from "reshaped"

import { modalsStore } from "#modals"
import AmazonS3 from "../../../../public/icons/amazonS3.svg?react"
import Clickhouse from "../../../../public/icons/clickhouse.svg?react"
import Webhook from "../../../../public/icons/webhook.svg?react"
import { CardRadio } from "../../../components/PipelineCreation/CardRadio/component"
import { ExpandableTabTopPanel } from "../../../components/PipelineCreation/ExpandableTab/component"
import { getCreatePipelineFunction } from "../../../utils"
import { pipelineCreatePageStore } from "../page.model"

export const SinkOptions = [
	{ value: "sdk", name: "SDK", icon: <Icon size={5} svg={<GitHub />} /> },
	{
		value: "webhook",
		name: "Webhook",
		badge: "managed",
		icon: (
			<Webhook
				height="24px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "clickhouse",
		name: "ClickHouse",
		badge: "managed",
		icon: (
			<Clickhouse
				height="19px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "amazon_s3",
		name: "Amazon S3",
		badge: "managed",
		icon: (
			<AmazonS3
				height="19px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
]

export const DataSink = observer(() => {
	let details: React.JSX.Element | null = null
	const store = pipelineCreatePageStore
	const sinkModalStore = modalsStore.createPipelineSink

	if (store.sinkForm.connector.type === "webhook") {
		let headers = (
			<>
				<View paddingBottom={1}>
					<Text variant="body-3" weight="medium">
						Headers
					</Text>
				</View>

				<View gap={4}>
					{store.sinkForm.connector.meta.headers.map(
						({ name, value }, index) => {
							return (
								// biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
								<View gap={4}>
									<View gap={4} direction="row">
										<View direction="row" grow gap={4}>
											<View.Item columns={6}>
												<TextField
													name={`headerKey${index}`}
													placeholder="Name"
													value={name}
													onChange={({ value }) => {
														if (
															store.sinkForm
																.connector
																.type ===
															"webhook"
														) {
															store.sinkForm.connector.meta.setHeaderKey(
																index,
																value,
															)
														}
													}}
												/>
											</View.Item>
											<View.Item columns={6}>
												<TextField
													name={`headerValue${index}`}
													placeholder="Value"
													value={value}
													onChange={({ value }) => {
														if (
															store.sinkForm
																.connector
																.type ===
															"webhook"
														) {
															store.sinkForm.connector.meta.setHeaderValue(
																index,
																value,
															)
														}
													}}
												/>
											</View.Item>
										</View>

										<Button
											icon={Trash2}
											color="critical"
											variant="outline"
											onClick={() => {
												if (
													store.sinkForm.connector
														.type === "webhook"
												) {
													store.sinkForm.connector.meta.removeHeader(
														index,
													)
												}
											}}
										/>
									</View>
								</View>
							)
						},
					)}

					<View.Item columns={3}>
						<Button
							fullWidth
							color="positive"
							variant="faded"
							onClick={() => {
								if (
									store.sinkForm.connector.type === "webhook"
								) {
									store.sinkForm.connector.meta.addHeader()
								}
							}}
						>
							Add Header
						</Button>
					</View.Item>
				</View>
			</>
		)

		details = (
			<View gap={4} direction="column" paddingTop={6} key="webhook">
				<Text variant="body-2" attributes={{ style: { width: "65%" } }}>
					Use GlassFlow managed Webhook connector. GlassFlow will
					publish transformed data automatically to the Webhook URL
					defined below.
				</Text>
				<Text variant="title-6">Connector Details</Text>

				<View direction="row" wrap gap={4}>
					<View.Item columns={2}>
						<FormControl>
							<FormControl.Label>Method</FormControl.Label>
							<Select
								options={[
									{ label: "POST", value: "POST" },
									{ label: "GET", value: "GET" },
									{ label: "PUT", value: "PUT" },
									{ label: "PATCH", value: "PATCH" },
								]}
								name="method"
								value={store.sinkForm.connector.meta.method}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"webhook"
									) {
										store.sinkForm.connector.meta.setMethod(
											event.value as
												| "POST"
												| "GET"
												| "PUT"
												| "PATCH",
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
						</FormControl>
					</View.Item>

					<View.Item columns={10}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.url.hasError
							}
						>
							<FormControl.Label>URL</FormControl.Label>
							<TextField
								name="url"
								value={store.sinkForm.connector.meta.url.value}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"webhook"
									) {
										store.sinkForm.connector.meta.setUrl(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{store.sinkForm.connector.meta.url.errorMessage}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={12}>{headers}</View.Item>
				</View>
			</View>
		)
	} else if (store.sinkForm.connector.type === "clickhouse") {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="webhook">
				<Text variant="body-2" attributes={{ style: { width: "65%" } }}>
					Use GlassFlow managed ClickHouse connector. GlassFlow will
					publish transformed data automatically to your ClickHouse
					database.
				</Text>
				<Text variant="title-6">Connector Details</Text>
				<View direction="row" wrap gap={4}>
					<View.Item columns={12}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.addr.hasError
							}
						>
							<FormControl.Label>Address</FormControl.Label>
							<TextField
								name="addr"
								value={store.sinkForm.connector.meta.addr.value}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"clickhouse"
									) {
										store.sinkForm.connector.meta.setAddress(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.addr
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.database.hasError
							}
						>
							<FormControl.Label>Database</FormControl.Label>
							<TextField
								name="database"
								value={
									store.sinkForm.connector.meta.database.value
								}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"clickhouse"
									) {
										store.sinkForm.connector.meta.setDatabase(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.database
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.table.hasError
							}
						>
							<FormControl.Label>Table</FormControl.Label>
							<TextField
								name="table"
								value={
									store.sinkForm.connector.meta.table.value
								}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"clickhouse"
									) {
										store.sinkForm.connector.meta.setTable(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.table
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.username.hasError
							}
						>
							<FormControl.Label>User Name</FormControl.Label>
							<TextField
								name="username"
								value={
									store.sinkForm.connector.meta.username.value
								}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"clickhouse"
									) {
										store.sinkForm.connector.meta.setUsername(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.username
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.password.hasError
							}
						>
							<FormControl.Label>Password</FormControl.Label>
							<TextField
								name="password"
								value={
									store.sinkForm.connector.meta.password.value
								}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"clickhouse"
									) {
										store.sinkForm.connector.meta.setPassword(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
									type: "password",
								}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.password
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>
				</View>
			</View>
		)
	} else if (store.sinkForm.connector.type === "amazon_s3") {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="webhook">
				<Text variant="body-2" attributes={{ style: { width: "65%" } }}>
					Use GlassFlow managed Amazon S3 connector. GlassFlow will
					publish transformed data automatically to your S3 file.
				</Text>
				<Text variant="title-6">Connector Details</Text>
				<View direction="row" wrap gap={4}>
					<View.Item columns={12}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.s3_bucket.hasError
							}
						>
							<FormControl.Label>Bucket Name</FormControl.Label>
							<TextField
								name="s3_bucket"
								value={
									store.sinkForm.connector.meta.s3_bucket
										.value
								}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"amazon_s3"
									) {
										store.sinkForm.connector.meta.setBucket(
											event.value,
										)
									}
								}}
								inputAttributes={{}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.s3_bucket
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.s3_key.hasError
							}
						>
							<FormControl.Label>Folder Name</FormControl.Label>
							<TextField
								name="s3_key"
								value={
									store.sinkForm.connector.meta.s3_key.value
								}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"amazon_s3"
									) {
										store.sinkForm.connector.meta.setKey(
											event.value,
										)
									}
								}}
								inputAttributes={{}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.s3_key
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.region.hasError
							}
						>
							<FormControl.Label>AWS Region</FormControl.Label>
							<TextField
								name="region"
								value={
									store.sinkForm.connector.meta.region.value
								}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"amazon_s3"
									) {
										store.sinkForm.connector.meta.setRegion(
											event.value,
										)
									}
								}}
								inputAttributes={{}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.region
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.accessKey.hasError
							}
						>
							<FormControl.Label>
								AWS Access Key
							</FormControl.Label>
							<TextField
								name="accessKey"
								value={
									store.sinkForm.connector.meta.accessKey
										.value
								}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"amazon_s3"
									) {
										store.sinkForm.connector.meta.setAccessKey(
											event.value,
										)
									}
								}}
								inputAttributes={{}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.accessKey
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sinkForm.connector.meta.secretKey.hasError
							}
						>
							<FormControl.Label>
								AWS Secret Key
							</FormControl.Label>
							<TextField
								name="secretKey"
								value={
									store.sinkForm.connector.meta.secretKey
										.value
								}
								onChange={(event) => {
									if (
										store.sinkForm.connector.type ===
										"amazon_s3"
									) {
										store.sinkForm.connector.meta.setSecretKey(
											event.value,
										)
									}
								}}
								inputAttributes={{}}
							/>
							<FormControl.Error>
								{
									store.sinkForm.connector.meta.secretKey
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>
				</View>
			</View>
		)
	} else if (store.sinkForm.connector.type === "sdk") {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="sdk">
				<Text variant="body-2" attributes={{ style: { width: "65%" } }}>
					After creating the pipeline, you can go to a jupyter
					notebook on github and learn how to consume transformed data
					with python SDK.{" "}
				</Text>
			</View>
		)
	}

	return (
		<>
			<ExpandableTabTopPanel
				title="Sink"
				onClose={sinkModalStore.closeModal}
				onNextClick={() => {}}
				onPrevClick={() => {}}
				validate={store.isSinkFormValid}
				prevButtonName=""
				nextButtonName=""
				withClose
			/>
			<View paddingTop={4}>
				<View>
					<View
						paddingBottom={6}
						direction="row"
						gap={3}
						align="center"
					>
						<Text variant="body-2">
							Select a managed connector or choose SDK to consume
							data using python{" "}
							<Link
								href="https://docs.glassflow.dev/concepts/data-sink"
								attributes={{ target: "_blank" }}
								variant="plain"
								icon={Info}
							>
								Learn more
							</Link>
						</Text>
					</View>
					<View direction="row" align="stretch" gap={4}>
						<RadioGroup
							name="source"
							onChange={(event) => {
								store.sinkForm.setType(
									event.value as
										| "sdk"
										| "webhook"
										| "clickhouse"
										| "amazon_s3",
								)
							}}
						>
							{SinkOptions.map((option) => (
								<CardRadio
									key={option.value}
									isSelected={
										store.sinkForm.connector.type ===
										option.value
									}
									value={option.value}
									name={option.name}
									badge={option.badge}
									icon={option.icon}
								/>
							))}
						</RadioGroup>
					</View>
					{details}
				</View>
			</View>
		</>
	)
})
