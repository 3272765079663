import classNames from "classnames"
import { Button, Hidden, Text, View } from "reshaped"
import type { Question } from "../page"
import { Header } from "../page"
import css from "../page.module.css"

type UseCaseProps = {
	step: Question
	setActiveQuestionIndex: (index: number) => void
	selectAnswer: (index: number, answer: string) => void
	isSecondQuestionAnswersShown: boolean
	showSecondQuestionAnswers: (show: boolean) => void
}
export const UseCase: React.FunctionComponent<UseCaseProps> = (props) => {
	return (
		<View
			borderRadius="large"
			borderColor={{ s: undefined, m: "neutral" }}
			padding={8}
			width="100vw"
			maxWidth="900px"
		>
			<Header />
			<View>
				<Hidden
					hide={{ s: props.isSecondQuestionAnswersShown, m: false }}
				>
					<Text
						variant={{ s: "featured-2", m: "featured-1" }}
						weight="medium"
					>
						{props.step.question}
					</Text>
				</Hidden>
				<View
					paddingTop={12}
					direction={{ s: "column", m: "row" }}
					align={{ s: "start", m: "center" }}
					gap={4}
					maxWidth="650px"
				>
					<View
						className={classNames({
							[css.activeAnswer]:
								props.isSecondQuestionAnswersShown,
						})}
					>
						<Button
							className={css.answer}
							color="neutral"
							rounded
							variant="faded"
							onClick={() =>
								props.showSecondQuestionAnswers(true)
							}
						>
							Yes, I have a use case
						</Button>
					</View>
					<Hidden
						hide={{
							s: props.isSecondQuestionAnswersShown,
							m: false,
						}}
					>
						<Button
							className={css.answer}
							color="neutral"
							rounded
							variant="faded"
							onClick={() => {
								props.setActiveQuestionIndex(2)
								props.selectAnswer(1, "No, I am just exploring")
							}}
						>
							No, I am just exploring
						</Button>
					</Hidden>
				</View>
				{props.isSecondQuestionAnswersShown && (
					<View paddingTop={12}>
						<Text variant="featured-2" weight="medium">
							What is your use case about?
						</Text>
						<View
							paddingTop={12}
							direction={{ s: "column", m: "row" }}
							align={{ s: "start", m: "center" }}
							gap={4}
							maxWidth="630px"
						>
							{props.step.answers.map((answer) => (
								<Button
									className={css.answer}
									key={answer}
									color="neutral"
									rounded
									variant="faded"
									onClick={() => {
										props.setActiveQuestionIndex(2)
										props.selectAnswer(1, answer)
									}}
								>
									{answer}
								</Button>
							))}
						</View>
					</View>
				)}
			</View>
		</View>
	)
}
