import { observer } from "mobx-react"
import { Modal } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import css from "../../components/PipelineCreation/styles.module.css"
import { PipelineCreateType } from "../../enums"
import { pipelineCreatePageStore } from "../../pages/PipelineCreate/page.model"
import { Transform } from "../../pages/PipelineCreate/tabs/transform"
import { pipelineEmailEncryptionCreatePageStore } from "../../pages/PipelineCreateEmailEncryption/page.model"
import { Transform as TransformEmailEncryption } from "../../pages/PipelineCreateEmailEncryption/tabs/transform"
import { getPipelineCreateType } from "../../utils"

export const getView = () => {
	switch (getPipelineCreateType()) {
		case PipelineCreateType.EmailEncryption:
			return <TransformEmailEncryption />
		case PipelineCreateType.Blank:
			return <Transform />
		default:
			return <Transform />
	}
}

export const CreatePipelineTransformModal = observer(() => {
	const transformModalStore = modalsStore.createPipelineTransform
	const store = pipelineCreatePageStore
	const demoStore = pipelineEmailEncryptionCreatePageStore
	const location = useLocation()
	const isBlank = getPipelineCreateType() === PipelineCreateType.Blank

	useEffect(() => {
		if (location.raw.path !== "/pipelines/create") {
			transformModalStore.closeModal()
		}
	}, [location.raw.path, transformModalStore.closeModal])

	return (
		<Modal
			className="modal"
			active={transformModalStore.isOpen}
			padding={6}
			size="1024px"
			onClose={async () => {
				if (isBlank) {
					if (await store.isTransformerFormValid()) {
						transformModalStore.closeModal()
					}
				} else {
					transformModalStore.closeModal()
					modalsStore.onboardingTip.openModal(
						"Here, you can see all the pipeline details before creating it.<br/>We’ve already set the data source and sink for you.<br/><br/>Click <b>Deploy Pipeline</b> to continue.",
						"465px",
						"showedDeployPipelineTip",
						demoStore.onboardingTips.deployPipeline,
						true,
						true,
					)
					setTimeout(() => {
						document.body.style.overflow = ""
					}, 1000)
				}
			}}
			blurredOverlay
			overlayClassName={css.overlay0}
		>
			{getView()}
		</Modal>
	)
})
