import { observer } from "mobx-react"
import { Suspense } from "preact/compat"
import { useEffect } from "preact/hooks"
import {
	Button,
	FormControl,
	HiddenVisually,
	Text,
	TextField,
	View,
} from "reshaped"

import { appStore } from "#app"
import { AceEditor } from "#components/AceEditor"

import { pipelineEmailEncryptionCreatePageStore } from "./page.model"

import { modalsStore } from "#modals"
import { ExpandableTab } from "../../components/PipelineCreation/ExpandableTab/component"
import { registerCreatePipelineFunction } from "../../utils"

export const PipelineCreateEmailEncryption = observer(() => {
	if (!appStore.auth.isAuthenticated) {
		throw Error()
	}

	const store = pipelineEmailEncryptionCreatePageStore
	registerCreatePipelineFunction("showedDeployPipelineTip", () =>
		store.onboardingTips.setOnboardingTip("deployPipeline", true),
	)
	registerCreatePipelineFunction("showedWelcomeTip", () =>
		store.onboardingTips.setOnboardingTip("welcome", true),
	)
	registerCreatePipelineFunction("showedSourceTip", () => {
		store.onboardingTips.setOnboardingTip("source", true)
	})
	registerCreatePipelineFunction("showedSinkTip", () => {
		store.onboardingTips.setOnboardingTip("sink", true)
	})

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		modalsStore.createPipelineTransform.openModal()

		modalsStore.onboardingTip.openModal(
			"Welcome to the Email Encryption Demo!<br/><br/>Here, you have the transform function of the pipeline. In this demo, we have already written the Python function to encrypt customers' e-mail addresses. You will be able to change the code later.<br/><br/>Click <b>Continue</b> to move to the next step!",
			"582px",
			"showedWelcomeTip",
			store.onboardingTips.welcome,
			false,
		)
	}, [])

	return (
		<View paddingTop={4}>
			{/* Trigger the loading of the lazy Ace Chunk when we enter the page, not just when the transformation step is shown. */}
			<HiddenVisually>
				<Suspense fallback={null}>
					<AceEditor mode="python" />
				</Suspense>
			</HiddenVisually>
			{/* End of Trigger */}
			<View direction="column" gap={6}>
				<Text variant="title-3">Create</Text>
				<View gap={3} direction="row" align="stretch">
					<View.Item columns={4}>
						<ExpandableTab
							name="Dummy Data Generator"
							label="Source"
							onClick={() => {
								modalsStore.createPipelineSource.openModal()
								setTimeout(
									() =>
										modalsStore.onboardingTip.openModal(
											"You can connect your data source here while creating a pipeline.<br/>In this demo, we will use the Dummy Data Generator for the data source.<br/><br/>Click <b>Continue</b> to go back.",
											"545px",
											"showedSourceTip",
											store.onboardingTips.source,
											true,
											true,
										),
									200,
								)
							}}
						/>
					</View.Item>
					<View.Item columns={4}>
						<ExpandableTab
							name="E-mail Encryption"
							label="Transform"
							onClick={() =>
								modalsStore.createPipelineTransform.openModal()
							}
						/>
					</View.Item>
					<View.Item columns={4}>
						<ExpandableTab
							name="GlassFlow Sink"
							label="Sink"
							onClick={() => {
								modalsStore.createPipelineSink.openModal()
								setTimeout(
									() =>
										modalsStore.onboardingTip.openModal(
											"You can connect your data sink to the desired destination here.<br/>In this demo, it is only possible to preview data in GlassFlow.<br/><br/>Click <b>Continue</b> to go back.",
											"484px",
											"showedSinkTip",
											store.onboardingTips.sink,
											true,
											true,
										),
									200,
								)
							}}
						/>
					</View.Item>
				</View>
				<View gap={4} direction="column" paddingTop={2}>
					<View.Item columns={4}>
						<FormControl hasError={store.setupForm.name.hasError}>
							<FormControl.Label>Pipeline Name</FormControl.Label>
							<TextField
								name="pipelineName"
								value={store.setupForm.name.value}
								onChange={(event) => {
									store.setupForm.setName(event.value)
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{store.setupForm.name.errorMessage}
							</FormControl.Error>
						</FormControl>
					</View.Item>
				</View>
				<View>
					<Button
						color="primary"
						loading={store.isCreating}
						onClick={async () => {
							store.submit()
						}}
					>
						Deploy Pipeline
					</Button>
				</View>
			</View>
		</View>
	)
})
