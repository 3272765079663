import { observer } from "mobx-react"
import { Text, View } from "reshaped"

export const NotFound = observer(() => {
	return (
		<View gap={3} paddingTop={4}>
			<View paddingBottom={1}>
				<Text variant="title-3">Page not found</Text>
			</View>
			<Text variant="body-2">
				We could not find what you are looking for.
			</Text>
		</View>
	)
})
