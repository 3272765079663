import { observer } from "mobx-react"
import { Modal } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import css from "../../components/PipelineCreation/styles.module.css"
import { PipelineCreateType } from "../../enums"
import { pipelineCreatePageStore } from "../../pages/PipelineCreate/page.model"
import { DataSink } from "../../pages/PipelineCreate/tabs/dataSink"
import { DataSink as DataSinkEmailEncryption } from "../../pages/PipelineCreateEmailEncryption/tabs/dataSink"
import { getPipelineCreateType } from "../../utils"

export const getView = () => {
	switch (getPipelineCreateType()) {
		case PipelineCreateType.EmailEncryption:
			return <DataSinkEmailEncryption />
		case PipelineCreateType.Blank:
			return <DataSink />
		default:
			return <DataSink />
	}
}

export const CreatePipelineSinkModal = observer(() => {
	const sinkModalStore = modalsStore.createPipelineSink
	const store = pipelineCreatePageStore
	const location = useLocation()
	const isBlank = getPipelineCreateType() === PipelineCreateType.Blank

	useEffect(() => {
		if (location.raw.path !== "/pipelines/create") {
			sinkModalStore.closeModal()
		}
	}, [location.raw.path, sinkModalStore.closeModal])

	return (
		<Modal
			className="modal"
			active={sinkModalStore.isOpen}
			padding={6}
			size="1024px"
			onClose={async () => {
				if (isBlank) {
					if (await store.isSinkFormValid()) {
						sinkModalStore.closeModal()
					}
				} else {
					sinkModalStore.closeModal()
					setTimeout(() => {
						document.body.style.overflow = ""
					}, 1000)
				}
			}}
			overlayClassName={css.overlay0}
			blurredOverlay
		>
			{getView()}
		</Modal>
	)
})
