import { observer } from "mobx-react"
import { Modal } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import css from "../../components/PipelineCreation/styles.module.css"
import { Transform } from "../../pages/PipelineDetail/tabs/transform"

export const PipelineTransformModal = observer(() => {
	const store = modalsStore.pipelineTransform
	const location = useLocation()

	useEffect(() => {
		if (!location.raw.path.includes("pipelines/")) {
			store.closeModal()
		}
	}, [location.raw.path, store.closeModal])

	return (
		<Modal
			className="modal"
			active={store.isOpen}
			padding={6}
			size="1024px"
			onClose={async () => store.closeModal()}
			blurredOverlay
			overlayClassName={css.overlay0}
		>
			<Transform />
		</Modal>
	)
})
