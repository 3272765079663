import classNames from "classnames"
import * as Fathom from "fathom-client"
import { observer } from "mobx-react"
import type { Instance } from "mobx-state-tree"
import { useEffect, useRef, useState } from "preact/hooks"
import { Copy, Download, Maximize2, Minimize2, X } from "react-feather"
import {
	Actionable,
	Badge,
	type BadgeProps,
	Button,
	FormControl,
	Loader,
	Select,
	Text,
	View,
	useHotkeys,
	useToast,
} from "reshaped"

import type { LogModel } from "#api"

import { demoPipelineDetailPageStore } from "../page.model"
import css from "./Logs.module.css"
import { Payload } from "./Payload"

const formatTimestamp = (timestamp: string) => {
	let parts = timestamp.split(".")

	parts[0] = parts[0].replace("T", " ")
	parts[1] = parts[1].slice(0, 3)

	return `${parts.join(".")}Z`
}

const Log = observer(
	(props: {
		log: Instance<typeof LogModel>
		index: number
	}) => {
		const toast = useToast()
		const store = demoPipelineDetailPageStore

		useHotkeys({
			Escape: () => {
				if (store.logs.fullscreenLogIndex === index) {
					requestAnimationFrame(() => {
						if (document.startViewTransition) {
							document
								.startViewTransition(() => {
									store.logs.closeFullScreen()
								})
								.updateCallbackDone.then(() => {
									for (let ref of Object.values(refs)) {
										if (ref.current) {
											ref.current.style.viewTransitionName =
												""
										}
									}
								})
						} else {
							store.logs.closeFullScreen()
						}
					})
				}
			},
		})

		let log = props.log
		let index = props.index

		let refs = {
			container: useRef<HTMLDivElement>(null),
			level: useRef<HTMLDivElement>(null),
			time: useRef<HTMLDivElement>(null),
			log: useRef<HTMLDivElement>(null),
			actions: useRef<HTMLDivElement>(null),
			fullscreenButton: useRef<HTMLDivElement>(null),
		}

		let renderInFullScreen = index === store.logs.fullscreenLogIndex

		let level = log.level
		let badgeColor: BadgeProps["color"]

		if (level === "CRITICAL") {
			badgeColor = "critical"
		} else if (level === "ERROR") {
			badgeColor = "critical"
		} else if (level === "WARNING") {
			badgeColor = "warning"
		} else if (level === "INFO") {
			badgeColor = "primary"
		} else if (level === "DEBUG") {
			badgeColor = undefined
		} else {
			badgeColor = undefined
			level = "CUSTOM"
		}

		return (
			<div
				className={classNames({
					[css.logRow]: true,
				})}
			>
				<div
					className={classNames({
						[css.container]: true,
						[css.fullscreen]: renderInFullScreen,
					})}
					ref={refs.container}
				>
					<div className={css.levelCell} ref={refs.level}>
						<Badge size="medium" color={badgeColor} rounded>
							{level}
						</Badge>
					</div>
					<div className={css.timeCell} ref={refs.time}>
						{formatTimestamp(log.timestamp)}
					</div>
					<div className={css.logCell}>
						<div>{log.payload.message}</div>
					</div>

					{renderInFullScreen ? (
						<div className={css.actionsCell}>
							<Button
								icon={Copy}
								color="neutral"
								size="small"
								onClick={async () => {
									await navigator.clipboard.writeText(
										JSON.stringify(log.payload),
									)

									const id = toast.show({
										color: "neutral",
										icon: Copy,
										title: "Copied Log Payload",
										actionsSlot: (
											<Button
												variant="ghost"
												icon={X}
												onClick={() => toast.hide(id)}
											/>
										),

										timeout: "short",
										position: "bottom-end",
									})
								}}
							>
								Copy Payload
							</Button>
						</div>
					) : null}
					<div className={css.fullscreenCell}>
						{renderInFullScreen ? (
							<Button
								ref={refs.fullscreenButton}
								variant="solid"
								color="neutral"
								size="small"
								icon={Minimize2}
								onClick={() => {
									requestAnimationFrame(() => {
										if (document.startViewTransition) {
											document
												.startViewTransition(() => {
													store.logs.closeFullScreen()
												})
												.updateCallbackDone.then(() => {
													for (let ref of Object.values(
														refs,
													)) {
														if (ref.current) {
															ref.current.style.viewTransitionName =
																""
														}
													}
												})
										} else {
											store.logs.closeFullScreen()
										}
									})
								}}
							/>
						) : (
							<Button
								ref={refs.fullscreenButton}
								variant="solid"
								color="neutral"
								size="small"
								icon={Maximize2}
								disabled={store.logs.fetch.isFetching}
								onClick={() => {
									for (let [name, ref] of Object.entries(
										refs,
									)) {
										if (ref.current) {
											ref.current.style.viewTransitionName =
												name
										}
									}

									if (document.startViewTransition) {
										document.startViewTransition(() => {
											store.logs.showLogFullScreen(index)
										})
									} else {
										store.logs.showLogFullScreen(index)
									}
								}}
							/>
						)}
					</div>

					{renderInFullScreen &&
					JSON.stringify(log.payload) !== "{}" ? (
						<div class={css.payloadCell}>
							<Payload
								payload={
									log.payload as unknown as Record<
										string,
										unknown
									>
								}
							/>
						</div>
					) : null}
				</div>
			</div>
		)
	},
)

export const RefreshIndicator = () => {
	const refreshInterval = 20
	const [now, setNow] = useState<number>(Date.now())
	const [secondsSinceLastFetch, setSecondsSinceLastFetch] =
		useState<number>(-0)
	const [secondsUntilFetch, setSecondsUntilFetch] = useState<number>(20)
	const store = demoPipelineDetailPageStore

	useEffect(() => {
		const interval = setInterval(() => {
			setNow(Date.now())

			if (store.logs.lastFetch) {
				setSecondsSinceLastFetch(
					-Math.abs(
						Math.round(store.logs.lastFetch / 1000) -
							Math.round(now / 1000),
					),
				)
				setSecondsUntilFetch(
					Math.round(store.logs.lastFetch / 1000) +
						refreshInterval -
						Math.round(now / 1000) -
						1,
				)
			}

			if (
				!store.logs.fetch.isFetching &&
				store.logs.lastFetch &&
				Math.round(store.logs.lastFetch / 1000) +
					refreshInterval -
					Math.round(now / 1000) -
					1 <
					1 &&
				!document.hidden
			) {
				store.logs.getLogs({
					fetchingStyle: "autoRefresh",
				})
			}
		}, 100)

		return () => {
			clearInterval(interval)
		}
	})

	const refreshFormatter = new Intl.RelativeTimeFormat("en-US", {
		style: "narrow",
	})

	if (store.logs.lastFetch !== null) {
		if (!store.logs.fetch.isFetching) {
			return (
				<Text
					variant="caption-1"
					className={css.refreshIndicator}
					key="refreshIndicator"
				>
					Last updated{" "}
					{refreshFormatter.format(secondsSinceLastFetch, "seconds")},
					refreshing{" "}
					{refreshFormatter.format(secondsUntilFetch, "seconds")}.{" "}
					<Actionable
						disabled={store.logs.lastFetchWasForced}
						className={classNames({
							[css.refreshButton]: true,
						})}
						onClick={() => {
							if (!store.logs.lastFetchWasForced) {
								store.logs.getLogs({
									fetchingStyle: "forcedRefresh",
								})
							}
						}}
					>
						Refresh Now
					</Actionable>
				</Text>
			)
		}

		if (
			store.logs.fetch.isFetching &&
			store.logs.fetch.fetchingStyle === "autoRefresh"
		) {
			return <Text variant="caption-1">Refreshing now...</Text>
		} else {
			return null
		}
	} else {
		return null
	}
}

export const Logs = observer(() => {
	const store = demoPipelineDetailPageStore

	return (
		<View gap={4} direction="column">
			<View
				direction="row"
				align="end"
				justify="space-between"
				width="100%"
			>
				<View direction="row" gap={6}>
					<FormControl>
						<FormControl.Label>Minimum Severity</FormControl.Label>
						<Select
							name="level"
							disabled={store.logs.fetch.isFetching}
							value={store.logs.filters.severityLevel.toString()}
							options={[
								{
									label: "Debug",
									value: "100",
								},
								{
									label: "Info",
									value: "200",
								},
								{
									label: "Warning",
									value: "400",
								},
								{
									label: "Error",
									value: "500",
								},
							]}
							onChange={(event) => {
								store.logs.filters.setSeverityLevel(
									Number(event.value) as
										| 100
										| 200
										| 400
										| 500,
								)
								store.logs.getLogs({
									fetchingStyle: "filterChange",
								})
							}}
						/>
					</FormControl>

					<FormControl>
						<FormControl.Label>Time Frame</FormControl.Label>
						<Select
							name="level"
							disabled={store.logs.fetch.isFetching}
							value={store.logs.filters.timeframe}
							options={[
								{
									label: "Last Hour",
									value: "oneHour",
								},
								{
									label: "Last 6 Hours",
									value: "sixHours",
								},
								{
									label: "Last 24 Hours",
									value: "oneDay",
								},
								{
									label: "Last 7 Days",
									value: "sevenDays",
								},
							]}
							onChange={(event) => {
								store.logs.filters.setTimeframe(
									event.value as
										| "oneHour"
										| "sixHours"
										| "oneDay"
										| "sevenDays",
								)
								store.logs.getLogs({
									fetchingStyle: "filterChange",
								})
							}}
						/>
					</FormControl>
				</View>

				<Button
					icon={Download}
					color="neutral"
					variant="solid"
					disabled={
						store.logs.fetch.isFetching || store.logs.hasNoLogs
					}
					href={window.URL.createObjectURL(
						new Blob([JSON.stringify(store.logs.logs)], {
							type: "application/json",
						}),
					)}
					onClick={() => {
						Fathom.trackEvent("Pipeline:Logs Download")
					}}
					attributes={{
						download: "Logs",
					}}
				>
					Download Logs
				</Button>
			</View>

			{!store.logs.hasNoLogs ? <RefreshIndicator /> : null}

			<div class={css.logs}>
				{store.logs.hasMorePages &&
				(store.logs.fetch.isFetching === false ||
					(store.logs.fetch.isFetching &&
						store.logs.fetch.fetchingStyle === "nextPage")) ? (
					<View
						className={css.olderLogsAvailable}
						height={10}
						direction="row"
						align="center"
						justify="center"
						gap={1}
					>
						<Text>
							Older logs in the selected timeframe are available.
						</Text>
						<Button
							size="small"
							variant="ghost"
							color="primary"
							disabled={
								store.logs.fetch.isFetching &&
								store.logs.fetch.fetchingStyle !== "nextPage"
							}
							loading={
								store.logs.fetch.isFetching &&
								store.logs.fetch.fetchingStyle === "nextPage"
							}
							onClick={() => {
								store.logs.getLogs({
									fetchingStyle: "nextPage",
								})
								Fathom.trackEvent(
									"Pipeline:Logs Load Next Page",
								)
							}}
						>
							Load Logs
						</Button>
					</View>
				) : null}

				{store.logs.fetch.isFetching &&
				store.logs.fetch.fetchingStyle !== "nextPage" &&
				store.logs.fetch.fetchingStyle !== "autoRefresh" ? (
					<View align="center" justify="center" height={20}>
						<Loader size="medium" />
					</View>
				) : store.logs.hasNoLogs ? (
					<View align="center" justify="center" height={20} gap={4}>
						<Text align="center">
							There are no logs in this timeframe with this
							severity.
						</Text>
						<RefreshIndicator />
					</View>
				) : (
					store.logs.logs.map((log, index) => {
						return (
							<Log
								log={log}
								index={index}
								key={JSON.stringify(log)}
							/>
						)
					})
				)}
			</div>
		</View>
	)
})
