let featureFlags: { [id: string]: boolean } = {}

try {
	featureFlags = JSON.parse(
		import.meta.env.VITE_FEATURE_FLAGS
			? import.meta.env.VITE_FEATURE_FLAGS
			: "{}",
	)
} catch (e) {
	console.log("Feature flags parsing", e)
}

const hasFlag = (key: string) => {
	if (!(key in featureFlags)) {
		return false
	}
	return featureFlags[key]
}

export const featureFlagNotificationsCenter = () => {
	return hasFlag("notification_center")
}
