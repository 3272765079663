import { Icon, RadioGroup, Text, View } from "reshaped"

import { GitHub } from "react-feather"
import { modalsStore } from "#modals"
import Clickhouse from "../../../../public/icons/clickhouse.svg?react"
import Webhook from "../../../../public/icons/webhook.svg?react"
import { CardRadio } from "../../../components/PipelineCreation/CardRadio/component"
import { ExpandableTabTopPanel } from "../../../components/PipelineCreation/ExpandableTab/component"
import { pipelineEmailEncryptionCreatePageStore } from "../page.model"

export const SinkOptions = [
	{ value: "glassflow_sink", name: "GlassFlow Sink" },
	{ value: "sdk", name: "SDK", icon: <Icon size={5} svg={<GitHub />} /> },
	{
		value: "webhook",
		name: "Webhook",
		icon: (
			<Webhook
				height="24px"
				style={{
					color: "var(--rs-color-border-neutral-faded)",
				}}
			/>
		),
	},
	{
		value: "clickhouse",
		name: "ClickHouse",
		icon: (
			<Clickhouse
				height="19px"
				style={{
					color: "var(--rs-color-border-neutral-faded)",
				}}
			/>
		),
	},
]

export const DataSink = () => {
	const sinkModalStore = modalsStore.createPipelineSink
	const store = pipelineEmailEncryptionCreatePageStore

	return (
		<>
			<ExpandableTabTopPanel
				title="Sink"
				onClose={sinkModalStore.closeModal}
				onNextClick={() => {
					store.onboardingTips.setOnboardingTip("sink", true)
				}}
				onPrevClick={() => {}}
				validate={undefined}
				prevButtonName=""
				nextButtonName="Continue"
			/>
			<View paddingTop={4}>
				<View minHeight={140}>
					<View
						paddingBottom={6}
						direction="row"
						gap={3}
						align="center"
					>
						<Text variant="body-1">
							Preview your data in GlassFlow web application for
							testing purposes.
						</Text>
					</View>
					<View direction="row" align="stretch" gap={4}>
						<RadioGroup name="source">
							{SinkOptions.map((option) => (
								<CardRadio
									key={option.value}
									isSelected={
										option.value === SinkOptions[0].value
									}
									value={option.value}
									name={option.name}
									isDisabled={
										option.value !== SinkOptions[0].value
									}
									icon={option.icon}
								/>
							))}
						</RadioGroup>
					</View>
				</View>
			</View>
		</>
	)
}
