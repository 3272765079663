import { observer } from "mobx-react"
import { Modal } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import css from "../../components/PipelineCreation/styles.module.css"
import { pipelineCreatePageStore } from "../../pages/PipelineCreate/page.model"
import { DataSink } from "../../pages/PipelineDetail/tabs/dataSink"

export const PipelineSinkModal = observer(() => {
	const sinkModalStore = modalsStore.pipelineSink
	const store = pipelineCreatePageStore
	const location = useLocation()

	useEffect(() => {
		if (!location.raw.path.includes("pipelines/")) {
			sinkModalStore.closeModal()
		}
	}, [location.raw.path, sinkModalStore.closeModal])

	return (
		<Modal
			className="modal"
			active={sinkModalStore.isOpen}
			padding={6}
			size="1024px"
			onClose={async () => {
				if (await store.isSinkFormValid()) {
					sinkModalStore.closeModal()
				}
			}}
			overlayClassName={css.overlay0}
			blurredOverlay
		>
			<DataSink isEdit={true} onDiscard={() => {}} onSave={() => {}} />
		</Modal>
	)
})
