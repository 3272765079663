import * as Fathom from "fathom-client"
import { flow, types } from "mobx-state-tree"

import { api } from "#api"

let updateCallback: () => Promise<void>

export const DeleteSpaceModalModel = types
	.model("Deletion", {
		isOpen: types.optional(types.boolean, false),
		isDeleting: types.optional(types.boolean, false),
		spaceId: types.optional(types.string, ""),
		spaceName: types.optional(types.string, ""),
		error: types.maybe(types.frozen()),
	})
	.actions((self) => {
		const openModal = (
			spaceId: string,
			spaceName: string,
			callback: () => Promise<void> = async () => {},
		) => {
			Fathom.trackEvent("Space:Delete Started")
			updateCallback = callback

			self.spaceId = spaceId
			self.spaceName = spaceName

			self.isOpen = true
			self.error = undefined
		}

		const closeModal = () => {
			self.isOpen = false
		}

		const deleteSpace = flow(function* () {
			try {
				self.isDeleting = true

				yield Promise.all([
					new Promise((resolve) => setTimeout(resolve, 500)),
					api.deleteSpace(undefined, {
						params: { space_id: self.spaceId },
					}),
				])

				Fathom.trackEvent("Space:Delete Success")

				try {
					yield updateCallback()
				} catch (error) {}

				self.isDeleting = false

				closeModal()

				yield new Promise((resolve) => setTimeout(resolve, 200))

				self.isDeleting = false

				return true
			} catch (error) {
				self.isDeleting = false
				self.error = error

				return false
			}
		})

		return { openModal, closeModal, deleteSpace }
	})
