import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import { ActionBar, Alert, Button, Modal, View, useToast } from "reshaped"

import { modalsStore } from "#modals"

export const DeleteAccessTokenModal = observer(() => {
	let deleteAccessTokenModalStore = modalsStore.deleteAccessToken

	const toast = useToast()
	let alert: React.JSX.Element | null = null

	const deleteToken = async (tokenName: string) => {
		if (await deleteAccessTokenModalStore.deleteToken()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: `"${tokenName}" deleted.`,
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (deleteAccessTokenModalStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={deleteAccessTokenModalStore.isOpen}
			padding={0}
			size="500px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Delete Token</Modal.Title>
				<Modal.Subtitle>
					Are you sure you want to delete "
					{deleteAccessTokenModalStore.tokenName}"?
				</Modal.Subtitle>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						disabled={deleteAccessTokenModalStore.isDeleting}
						onClick={() => {
							deleteAccessTokenModalStore.closeModal()
						}}
					>
						Cancel
					</Button>
					<Button
						color="critical"
						loading={deleteAccessTokenModalStore.isDeleting}
						onClick={() => {
							deleteToken(deleteAccessTokenModalStore.tokenName)
						}}
					>
						Delete Token
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
