import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const Error = z.object({ detail: z.string() }).passthrough();

export const schemas = {
  Error,
};

const endpoints = makeApi([
  {
    method: "post",
    path: "/events",
    alias: "postMetricEvents",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.array(z.object({}).partial().passthrough()),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 400,
        description: `Bad request`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
      {
        status: 500,
        description: `Unexpected error`,
        schema: z.object({ detail: z.string() }).passthrough(),
      },
    ],
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
