import { observer } from "mobx-react"
import { Button, FormControl, Tabs, View } from "reshaped"

import { getSnapshot } from "mobx-state-tree"
import { Globe } from "react-feather"
import { modalsStore } from "#modals"
import { CodeEditor } from "../../../components/PipelineCreation/CodeEditor/component"
import { ExpandableTabTopPanel } from "../../../components/PipelineCreation/ExpandableTab/component"
import { pipelineEmailEncryptionCreatePageStore } from "../page.model"

export const Transform = observer(() => {
	const store = pipelineEmailEncryptionCreatePageStore
	const transformModalStore = modalsStore.createPipelineTransform
	return (
		<>
			<ExpandableTabTopPanel
				title="Transform: E-mail Encryption"
				onClose={transformModalStore.closeModal}
				onNextClick={() => {
					modalsStore.onboardingTip.openModal(
						"Here, you can see all the pipeline details before creating it.<br/>We’ve already set the data source and sink for you.<br/><br/>Click <b>Deploy Pipeline</b> to continue.",
						"465px",
						"showedDeployPipelineTip",
						store.onboardingTips.deployPipeline,
						true,
						true,
					)
				}}
				onPrevClick={() => {}}
				validate={undefined}
				prevButtonName=""
				nextButtonName="Continue"
			/>
			<View paddingTop={4}>
				<View minHeight={140} gap={4}>
					<FormControl>
						<Tabs>
							<View
								justify="space-between"
								direction="row"
								align="center"
								gap={6}
							>
								<View grow>
									<Tabs.List>
										<Tabs.Item value="handler">
											handler.py
										</Tabs.Item>
										<Tabs.Item value="requirements">
											requirements.txt
										</Tabs.Item>
									</Tabs.List>
								</View>
								<Button
									color="neutral"
									endIcon={Globe}
									onClick={() => {
										modalsStore.editEnvironmentVariables.openModal(
											async () => {},
											true,
										)
									}}
								>
									Environment Variables
								</Button>
							</View>
							<Tabs.Panel value="handler">
								<CodeEditor
									softWrap={store.editorSettings.softWrap}
									showInvisibles={
										store.editorSettings.showInvisibles
									}
									value={store.transformerForm.handler.value}
									minHeight="300px"
									height="515px"
									paddingTop={3}
									readOnly
								/>
							</Tabs.Panel>
							<Tabs.Panel value="requirements">
								<CodeEditor
									softWrap={store.editorSettings.softWrap}
									showInvisibles={
										store.editorSettings.showInvisibles
									}
									value={
										store.transformerForm.requirements.value
									}
									minHeight="300px"
									height="515px"
									paddingTop={3}
									readOnly
								/>
							</Tabs.Panel>
						</Tabs>
					</FormControl>
				</View>
			</View>
		</>
	)
})
