import { observer } from "mobx-react"
import {
	Button,
	FormControl,
	HiddenVisually,
	Link,
	Popover,
	Select,
	Switch,
	Tabs,
	Text,
	View,
} from "reshaped"

import { getSnapshot } from "mobx-state-tree"
import { createRef } from "preact"
import { Info, Layers, Settings, Upload } from "react-feather"
import { modalsStore } from "#modals"
import { CodeEditor } from "../../../components/PipelineCreation/CodeEditor/component"
import { ExpandableTabTopPanel } from "../../../components/PipelineCreation/ExpandableTab/component"
import { pipelineCreatePageStore } from "../page.model"

const templateOptions = [
	{
		disabled: true,
		label: "Custom",
		value: "",
	},
	{
		label: "Echo",
		value: "echo",
	},
	{
		label: "AI Anomaly Detection",
		value: "aiAnomalyDetection",
	},
	{
		label: "Mask IP Adresses",
		value: "maskIp",
	},
	{
		label: "PII Detection",
		value: "pii",
	},
	{
		label: "Filter Null Values",
		value: "removeNull",
	},
]

export const Transform = observer(() => {
	const store = pipelineCreatePageStore
	const transformModalStore = modalsStore.createPipelineTransform
	const fileInput = createRef<HTMLInputElement>()

	return (
		<>
			<ExpandableTabTopPanel
				title="Transform: Function"
				onClose={transformModalStore.closeModal}
				onNextClick={() => {}}
				onPrevClick={() => {}}
				validate={store.isTransformerFormValid}
				prevButtonName=""
				nextButtonName=""
				withClose
			/>
			<View paddingTop={4}>
				<View minHeight={140} gap={4}>
					<View
						paddingBottom={2}
						direction="row"
						gap={3}
						align="center"
					>
						<Text variant="body-2">
							Write your data transformation function below. You
							can also upload a .py file. Write your python file,
							requirements txt and environment variables you need
							for the function.{" "}
							<Link
								href="https://docs.glassflow.dev/develop/define-a-transformation-function"
								attributes={{ target: "_blank" }}
								variant="plain"
								icon={Info}
							>
								Learn more
							</Link>
						</Text>
					</View>
					<View direction="row" gap={3} align="end">
						<FormControl>
							<FormControl.Label>Template</FormControl.Label>
							<Select
								value={store.transformerForm.selectedTemplate}
								name="template"
								onChange={({ value }) => {
									if (value !== "") {
										store.transformerForm.selectTemplate(
											value,
										)

										if (value === "aiAnomalyDetection") {
											store.transformerForm.setRequirements(
												"openai>=1.42.0",
											)
										}
									}
								}}
								options={templateOptions}
							/>
						</FormControl>

						<HiddenVisually>
							<input
								type="file"
								ref={fileInput}
								accept=".py"
								onChange={() => {
									const files = fileInput.current?.files

									if (files?.length) {
										const file = files[0]

										let reader = new FileReader()

										reader.addEventListener(
											"load",
											(event) => {
												if (
													typeof event.target
														?.result === "string"
												) {
													store.transformerForm.setHandler(
														event.target.result,
													)
												}
											},
										)

										reader.readAsText(file, "UTF-8")
									}
								}}
							/>
						</HiddenVisually>
						<Button
							variant="outline"
							color="neutral"
							onClick={() => {
								fileInput.current?.click()
							}}
							icon={Upload}
						>
							Upload Handler File
						</Button>
						<View grow justify="end" direction="row" gap={3}>
							<Button
								color="primary"
								icon={Layers}
								onClick={() => {
									modalsStore.editEnvironmentVariables.openModal(
										async (environmentVariables) => {
											store.transformerForm.setEnvironmentVariables(
												environmentVariables,
											)
										},
									)
								}}
							>
								Environment Variables
							</Button>

							<Popover position="bottom-end">
								<Popover.Trigger>
									{(attributes) => (
										<Button
											attributes={attributes}
											icon={Settings}
											variant="outline"
											color="neutral"
										/>
									)}
								</Popover.Trigger>
								<Popover.Content>
									<View direction="column" gap={4}>
										<Switch
											size="small"
											name="softWrap"
											checked={
												store.editorSettings.softWrap
											}
											onChange={({ checked }) => {
												store.editorSettings.setSoftWrap(
													checked,
												)
											}}
										>
											Soft Wrap
										</Switch>
										<Switch
											size="small"
											name="showInvisibles"
											checked={
												store.editorSettings
													.showInvisibles
											}
											onChange={({ checked }) => {
												store.editorSettings.setShowInvisibles(
													checked,
												)
											}}
										>
											Show Invisibles
										</Switch>
									</View>
								</Popover.Content>
							</Popover>
						</View>
					</View>
					<FormControl
						hasError={store.transformerForm.handler.hasError}
					>
						<Tabs>
							<Tabs.List>
								<Tabs.Item value="handler">
									handler.py
								</Tabs.Item>
								<Tabs.Item value="requirements">
									requirements.txt
								</Tabs.Item>
							</Tabs.List>
							<Tabs.Panel value="handler">
								<CodeEditor
									softWrap={store.editorSettings.softWrap}
									showInvisibles={
										store.editorSettings.showInvisibles
									}
									value={store.transformerForm.handler.value}
									hasError={
										store.transformerForm.handler.hasError
									}
									onChange={(value) => {
										pipelineCreatePageStore.transformerForm.setHandler(
											value,
										)
										pipelineCreatePageStore.transformerForm.selectTemplate(
											"",
										)
									}}
									errorMessage={
										store.transformerForm.handler
											.errorMessage
									}
									minHeight="300px"
									height="44dvh"
									paddingTop={3}
								/>
							</Tabs.Panel>
							<Tabs.Panel value="requirements">
								<CodeEditor
									onChange={(value) => {
										pipelineCreatePageStore.transformerForm.setRequirements(
											value,
										)
										pipelineCreatePageStore.transformerForm.selectTemplate(
											"",
										)
									}}
									softWrap={store.editorSettings.softWrap}
									showInvisibles={
										store.editorSettings.showInvisibles
									}
									value={
										store.transformerForm.requirements.value
									}
									hasError={
										store.transformerForm.requirements
											.hasError
									}
									errorMessage={
										store.transformerForm.requirements
											.errorMessage
									}
									minHeight="300px"
									height="44dvh"
									paddingTop={3}
								/>
							</Tabs.Panel>
						</Tabs>
					</FormControl>
				</View>
			</View>
		</>
	)
})
