import { flow, types } from "mobx-state-tree"
import { api } from "#api"

export const ProfilePage = types
	.model({
		personalAccessToken: types.maybe(types.string),
	})
	.actions((self) => {
		return {
			loadAccessToken: flow(function* () {
				let response: Awaited<
					ReturnType<typeof api.listPersonalAccessTokens>
				> = yield api.listPersonalAccessTokens()

				if (response.tokens.length) {
					self.personalAccessToken = response.tokens[0]
				}
			}),
		}
	})

export const profilePageStore = ProfilePage.create({})
