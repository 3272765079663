import { observer } from "mobx-react"

import css from "./Payload.module.css"

const Bracket: React.FunctionComponent<{ text: string }> = (props) => {
	return <span class={css.bracket}>{props.text}</span>
}

const Dot: React.FunctionComponent<{ text: string }> = (props) => {
	return <span class={css.dot}>{props.text}</span>
}

const Key: React.FunctionComponent<{ text: string }> = (props) => {
	const KeySymbol = () => <span class={css.keySymbol}>{'"'}</span>

	return (
		<>
			<KeySymbol />
			<span class={css.key}>{props.text}</span>
			<KeySymbol />
		</>
	)
}

const StringValue: React.FunctionComponent<{ text: string }> = (props) => {
	const StringSymbol = () => <span class={css.stringSymbol}>{'"'}</span>

	return (
		<>
			<StringSymbol />
			<span class={css.stringValue}>{props.text}</span>
			<StringSymbol />
		</>
	)
}

const NullOrUndefinedValue: React.FunctionComponent<{ text: string }> = (
	props,
) => {
	return <span class={css.nullOrUndefinedValue}>{props.text}</span>
}

const BooleanValue: React.FunctionComponent<{ text: string }> = (props) => {
	return <span class={css.booleanValue}>{props.text}</span>
}

const NumberValue: React.FunctionComponent<{ text: string }> = (props) => {
	return <span class={css.numberValue}>{props.text}</span>
}

const formatJSONValue = (value: unknown, indentLevel: number) => {
	if (typeof value === "string") {
		return <StringValue text={value} />
	} else if (value === null || value === undefined) {
		return <NullOrUndefinedValue text={String(value)} />
	} else if (typeof value === "boolean") {
		return <BooleanValue text={String(value)} />
	} else if (typeof value === "number") {
		return <NumberValue text={String(value)} />
	} else if (Array.isArray(value)) {
		return formatJSONArray(value, indentLevel)
	} else if (typeof value === "object") {
		return formatJSONObject(value as Record<string, unknown>, indentLevel)
	}
}

const formatJSONArray = (
	array: Array<unknown>,
	indentLevel = 0,
): React.JSX.Element => {
	let items = []

	let breakItemsInLines =
		array.map((item) => JSON.stringify(item)).join(", ").length + 2 > 50

	for (let item of array) {
		items.push(
			<>
				{breakItemsInLines ? "\t".repeat(indentLevel + 1) : ""}
				{formatJSONValue(item, indentLevel + 1)}
			</>,
		)

		items.push(
			<>
				<Dot text="," />
				{breakItemsInLines ? "\n" : " "}
			</>,
		)
	}

	items = items.slice(0, -1)

	return (
		<>
			<Bracket text="[" />
			{breakItemsInLines ? "\n" : ""}
			{items}
			{breakItemsInLines ? "\n" : ""}
			{breakItemsInLines ? "\t".repeat(indentLevel) : ""}
			<Bracket text="]" />
		</>
	)
}

const formatJSONObject = (
	json: Record<string, unknown>,
	indentLevel = 0,
): React.JSX.Element => {
	let keyValuePairs = []

	for (let [key, value] of Object.entries(json)) {
		keyValuePairs.push(
			<>
				{"\t".repeat(indentLevel + 1)}
				<Key text={key} />
				<Dot text=":" /> {formatJSONValue(value, indentLevel + 1)}
			</>,
		)

		keyValuePairs.push(
			<>
				<Dot text="," />
				{"\n"}
			</>,
		)
	}

	keyValuePairs = keyValuePairs.slice(0, -1)

	return (
		<>
			<Bracket text="{" />
			{"\n"}
			{keyValuePairs}
			{"\n"}
			{"\t".repeat(indentLevel)}
			<Bracket text="}" />
		</>
	)
}

export const Payload = observer(
	({ payload }: { payload: Record<string, unknown> }) => {
		return <pre class={css.payload}>{formatJSONObject(payload)}</pre>
	},
)
