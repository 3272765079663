import { AnimatePresence } from "framer-motion"
import { useState } from "preact/hooks"
import { Maximize2, Minimize2 } from "react-feather"
import { Button, Icon, Text, View } from "reshaped"
import { AnimatedHorizontalPresence } from "../AnimatedHorizontalPresence/component"

type SinkDataRowProps = {
	text: string
	onClick?: () => void
}
export const SinkDataRow: React.FunctionComponent<SinkDataRowProps> = (
	props,
) => {
	const formattedJson = JSON.stringify(JSON.parse(props.text), null, 2)
	const [isExpanded, expand] = useState<boolean>(false)

	return (
		<AnimatePresence>
			<AnimatedHorizontalPresence key="glassFlowSinkRow">
				<View
					direction="row"
					align="center"
					padding={3}
					gap={2}
					attributes={{
						style: {
							borderBottom:
								"1px solid var(--rs-color-border-neutral-faded)",
						},
					}}
				>
					<View grow>
						{isExpanded ? (
							<Text variant="body-3">
								<pre
									style={{
										whiteSpace: "pre-wrap",
										wordWrap: "break-word",
									}}
								>
									{formattedJson}
								</pre>
							</Text>
						) : (
							<Text variant="body-3" maxLines={1}>
								{props.text}
							</Text>
						)}
					</View>
					<Button
						color="neutral"
						variant="solid"
						onClick={() => {
							expand(!isExpanded)
							props.onClick?.()
						}}
					>
						<Icon
							size={4}
							svg={isExpanded ? <Minimize2 /> : <Maximize2 />}
						/>
					</Button>
				</View>
			</AnimatedHorizontalPresence>
		</AnimatePresence>
	)
}
