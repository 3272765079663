import type { Notification as NovuNotification } from "@novu/js"
import { observer } from "mobx-react"
import { Bell } from "react-feather"
import { Badge, Button, Divider, Popover, Text, View, useToast } from "reshaped"

import { useEffect, useState } from "preact/hooks"
import { appStore } from "#app"

const createdAtFormatter = new Intl.RelativeTimeFormat("en", {})

export const Notification = observer(
	({ notification }: { notification: NovuNotification }) => {
		const [now, setNow] = useState<number>(Date.now())
		const [formattedCreatedAt, setFormattedCreatedAt] = useState("Just now")

		useEffect(() => {
			const interval = setInterval(() => {
				setNow(Date.now())

				const distanceInSeconds = Math.round(
					(now - new Date(notification.createdAt).getTime()) / 1000,
				)

				if (distanceInSeconds < 60) {
					setFormattedCreatedAt(
						createdAtFormatter.format(
							-distanceInSeconds,
							"seconds",
						),
					)
				} else if (distanceInSeconds / 60 < 60) {
					setFormattedCreatedAt(
						createdAtFormatter.format(
							-Math.round(distanceInSeconds / 60),
							"minutes",
						),
					)
				} else if (distanceInSeconds / 60 / 60 < 24) {
					setFormattedCreatedAt(
						createdAtFormatter.format(
							-Math.round(distanceInSeconds / 60 / 60),
							"hours",
						),
					)
				} else {
					setFormattedCreatedAt(
						createdAtFormatter.format(
							-Math.round(distanceInSeconds / 60 / 60 / 24),
							"days",
						),
					)
				}
			}, 100)

			return () => {
				clearInterval(interval)
			}
		})

		if (notification.isRead) {
			return (
				<View
					overflow="hidden"
					borderRadius="small"
					borderColor="disabled"
					backgroundColor="disabled-faded"
				>
					<View gap={1} padding={3} paddingTop={2}>
						<Text variant="body-3" color="disabled">
							{notification.body}
						</Text>
						<Text variant="caption-1" color="disabled">
							{formattedCreatedAt}
						</Text>
					</View>
				</View>
			)
		} else {
			return (
				<View
					overflow="hidden"
					backgroundColor="elevation-raised"
					borderRadius="small"
					borderColor="neutral"
				>
					<View gap={1} padding={3} paddingTop={2}>
						<Text variant="body-3">{notification.body}</Text>
						<Text variant="caption-1">{formattedCreatedAt}</Text>
					</View>
				</View>
			)
		}
	},
)

const PopoverTrigger = observer((props: { attributes: unknown }) => {
	return (
		<Badge.Container>
			<Button icon={Bell} variant="faded" attributes={props.attributes} />
			{appStore.auth.isAuthenticated &&
			appStore.auth.notifications.hasUnreadNotifications ? (
				<Badge rounded color="critical" />
			) : (
				""
			)}
		</Badge.Container>
	)
})

export const NotificationCenter = observer(() => {
	const toast = useToast()
	if (appStore.auth.isAuthenticated) {
		if (appStore.auth.notifications.notificationToBeToasted) {
			toast.show({
				color: "neutral",
				title: appStore.auth.notifications.notificationToBeToasted.body,
				timeout: 10000,
			})

			appStore.auth.notifications.toastHasBeenToasted()
		}

		return (
			<Popover position="bottom-end" width="350px" padding={0}>
				<Popover.Trigger>
					{(attributes) => {
						return <PopoverTrigger attributes={attributes} />
					}}
				</Popover.Trigger>
				<Popover.Content>
					<View
						direction="column"
						paddingBottom={2}
						backgroundColor="elevation-base"
					>
						<View
							direction="row"
							justify="space-between"
							height="44px"
							paddingBlock={1}
							paddingInline={3}
							align="center"
							backgroundColor="elevation-overlay"
						>
							<Text variant="title-6">Notification Center</Text>
							<Button
								color="primary"
								variant="ghost"
								size="small"
								onClick={() => {
									if (appStore.auth.isAuthenticated) {
										appStore.auth.notifications.markAllAsRead()
									}
								}}
							>
								Mark all as Read
							</Button>
						</View>
						<Divider />
						<View
							direction="column"
							padding={2}
							paddingBottom={0}
							maxHeight={"75dvh"}
							overflow="auto"
						>
							<View direction="column" gap={2}>
								{appStore.auth.notifications.notifications
									.length ? (
									appStore.auth.notifications.notifications.map(
										(notification) => {
											return (
												<Notification
													key={notification.id}
													notification={notification}
												/>
											)
										},
									)
								) : (
									<View
										padding={5}
										align="center"
										justify="center"
									>
										<Text>
											You have no notifications yet!
										</Text>
									</View>
								)}
							</View>
						</View>
					</View>
				</Popover.Content>
			</Popover>
		)
	} else {
		return null
	}
})
