import classNames from "classnames"
import { createRef } from "preact"
import { useEffect } from "preact/hooks"
import { Suspense } from "react"
import { FormControl, View, useTheme } from "reshaped"
import { AceEditor } from "../../AceEditor/component"
import css from "./codeEditor.module.css"

type CodeEditorProps = {
	readOnly?: boolean
	softWrap: boolean
	showInvisibles: boolean
	value: string
	hasError?: boolean
	errorMessage?: string
	height?: string
	minHeight?: string
	maxHeight?: string
	paddingTop?: number
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	onChange?: (value: string, event?: any) => void
}
export const CodeEditor: React.FunctionComponent<CodeEditorProps> = (props) => {
	const { colorMode } = useTheme()
	const editor = createRef()

	useEffect(() => {
		if (editor.current) {
			editor.current.editor.setOption("wrap", props.softWrap)

			editor.current.editor.setOption(
				"showInvisibles",
				props.showInvisibles,
			)
		}
	}, [editor, props.showInvisibles, props.softWrap])

	return (
		<View
			minHeight={props.minHeight}
			height={props.height}
			paddingTop={props.paddingTop}
			maxHeight={props.maxHeight}
		>
			<Suspense fallback={null}>
				<AceEditor
					ref={editor}
					readOnly={props.readOnly}
					onLoad={(editorInstance) => {
						editorInstance.setOption("wrap", props.softWrap)

						editorInstance.setOption(
							"showInvisibles",
							props.showInvisibles,
						)
					}}
					className={classNames({
						[css.editor]: true,
						[css.editorError]: props.hasError,
					})}
					mode="python"
					theme={colorMode === "dark" ? "dracula" : "xcode"}
					value={props.value}
					lineHeight="1.65"
					onChange={props.onChange}
					width="100%"
					height="100%"
					fontSize={14}
					showGutter
					tabSize={4}
					enableBasicAutocompletion
					enableLiveAutocompletion
					showPrintMargin={false}
					editorProps={{ $blockScrolling: true }}
				/>
			</Suspense>

			<FormControl.Error>{props.errorMessage}</FormControl.Error>
		</View>
	)
}
