import { observer } from "mobx-react"
import { lazy } from "preact/compat"

export const AceEditor = lazy(() => {
	const aceEditor = import("react-ace").then(async (reactAce) => {
		const aceBuilds = await import("ace-builds")

		const modePython = await import("ace-builds/src-noconflict/mode-python")
		const themeDracula = await import(
			"ace-builds/src-noconflict/theme-dracula"
		)
		const themeXcode = await import("ace-builds/src-noconflict/theme-xcode")
		const languageTools = await import(
			"ace-builds/src-noconflict/ext-language_tools"
		)

		aceBuilds.config.setModuleUrl("ace/mode/python", modePython)
		aceBuilds.config.setModuleUrl("ace/theme/dracula", themeDracula)
		aceBuilds.config.setModuleUrl("ace/theme/xcode", themeXcode)
		aceBuilds.config.setModuleUrl("language_tools", languageTools)

		return observer(reactAce)
	})

	return aceEditor
})
