import type { RefObject } from "preact"
import { useEffect } from "preact/hooks"

export const useOutsideClick = (
	ref: RefObject<HTMLElement>,
	callback: () => void,
) => {
	const handleClick = (e: MouseEvent) => {
		if (ref.current && !ref.current.contains(e.target as Node)) {
			callback()
		}
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClick)

		return () => {
			document.removeEventListener("mousedown", handleClick)
		}
		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	}, [handleClick])
}
