import { observer } from "mobx-react"
import { GitHub, Info } from "react-feather"
import {
	FormControl,
	Icon,
	Link,
	RadioGroup,
	Text,
	TextArea,
	TextField,
	View,
} from "reshaped"

import { modalsStore } from "#modals"
import Amazon from "../../../../public/icons/amazonSQS.svg?react"
import Google from "../../../../public/icons/googlePubSub.svg?react"
import Webhook from "../../../../public/icons/webhook.svg?react"
import { CardRadio } from "../../../components/PipelineCreation/CardRadio/component"
import { ExpandableTabTopPanel } from "../../../components/PipelineCreation/ExpandableTab/component"
import { pipelineCreatePageStore } from "../page.model"

export const SourceOptions = [
	{ value: "sdk", name: "SDK", icon: <Icon size={5} svg={<GitHub />} /> },
	{
		value: "webhook",
		name: "Webhook",
		icon: (
			<Webhook
				height="24px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "amazon_sqs",
		name: "Amazon SQS",
		badge: "managed",
		icon: (
			<Amazon
				height="24px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
	{
		value: "google_pubsub",
		name: "Google Pub/Sub",
		badge: "managed",
		icon: (
			<Google
				height="24px"
				style={{
					color: "var(--rs-color-on-background-neutral)",
				}}
			/>
		),
	},
]

export const DataSource = observer(() => {
	let details: React.JSX.Element | null = null
	const store = pipelineCreatePageStore
	const sourceModalStore = modalsStore.createPipelineSource

	if (store.sourceForm.connector.type === "google_pubsub") {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="google_pubsub">
				<Text variant="body-2" attributes={{ style: { width: "65%" } }}>
					Use GlassFlow managed Pub/Sub connector. GlassFlow will pull
					data automatically from your Pub/Sub and send it to the
					pipeline.
				</Text>
				<Text variant="title-6">Connector Details</Text>

				<View direction="row" wrap gap={4}>
					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sourceForm.connector.meta.projectId
									.hasError
							}
						>
							<FormControl.Label>Project ID</FormControl.Label>
							<TextField
								name="projectId"
								value={
									store.sourceForm.connector.meta.projectId
										.value
								}
								onChange={(event) => {
									if (
										store.sourceForm.connector.type ===
										"google_pubsub"
									) {
										store.sourceForm.connector.meta.setProjectId(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sourceForm.connector.meta.projectId
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sourceForm.connector.meta.subscriptionId
									.hasError
							}
						>
							<FormControl.Label>
								Subscription ID
							</FormControl.Label>
							<TextField
								name="subscriptionId"
								value={
									store.sourceForm.connector.meta
										.subscriptionId.value
								}
								onChange={(event) => {
									if (
										store.sourceForm.connector.type ===
										"google_pubsub"
									) {
										store.sourceForm.connector.meta.setSubscriptionId(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sourceForm.connector.meta
										.subscriptionId.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={12}>
						<FormControl
							hasError={
								store.sourceForm.connector.meta.credentials
									.hasError
							}
						>
							<FormControl.Label>
								Credentials JSON
							</FormControl.Label>
							<TextArea
								name="credentials"
								resize="none"
								value={
									store.sourceForm.connector.meta.credentials
										.value
								}
								onChange={(event) => {
									if (
										store.sourceForm.connector.type ===
										"google_pubsub"
									) {
										store.sourceForm.connector.meta.setCredentials(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sourceForm.connector.meta.credentials
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>
				</View>
			</View>
		)
	} else if (store.sourceForm.connector.type === "amazon_sqs") {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="amazon_sqs">
				<Text variant="body-2" attributes={{ style: { width: "65%" } }}>
					Use GlassFlow managed SQS connector. GlassFlow will pull
					data automatically from your SQS and send it to the
					pipeline.
				</Text>
				<Text variant="title-6">Connector Details</Text>

				<View direction="row" wrap gap={4}>
					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sourceForm.connector.meta.queueUrl
									.hasError
							}
						>
							<FormControl.Label>Queue URL</FormControl.Label>
							<TextField
								name="queueUrl"
								value={
									store.sourceForm.connector.meta.queueUrl
										.value
								}
								onChange={(event) => {
									if (
										store.sourceForm.connector.type ===
										"amazon_sqs"
									) {
										store.sourceForm.connector.meta.setQueueUrl(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sourceForm.connector.meta.queueUrl
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sourceForm.connector.meta.region.hasError
							}
						>
							<FormControl.Label>AWS Region</FormControl.Label>
							<TextField
								name="region"
								value={
									store.sourceForm.connector.meta.region.value
								}
								onChange={(event) => {
									if (
										store.sourceForm.connector.type ===
										"amazon_sqs"
									) {
										store.sourceForm.connector.meta.setRegion(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sourceForm.connector.meta.region
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sourceForm.connector.meta.accessKey
									.hasError
							}
						>
							<FormControl.Label>
								AWS Access Key
							</FormControl.Label>
							<TextField
								name="accessKey"
								value={
									store.sourceForm.connector.meta.accessKey
										.value
								}
								onChange={(event) => {
									if (
										store.sourceForm.connector.type ===
										"amazon_sqs"
									) {
										store.sourceForm.connector.meta.setAccessKey(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sourceForm.connector.meta.accessKey
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>

					<View.Item columns={6}>
						<FormControl
							hasError={
								store.sourceForm.connector.meta.secretKey
									.hasError
							}
						>
							<FormControl.Label>
								AWS Secret Key
							</FormControl.Label>
							<TextField
								name="secretKey"
								value={
									store.sourceForm.connector.meta.secretKey
										.value
								}
								onChange={(event) => {
									if (
										store.sourceForm.connector.type ===
										"amazon_sqs"
									) {
										store.sourceForm.connector.meta.setSecretKey(
											event.value,
										)
									}
								}}
								inputAttributes={{
									"data-1p-ignore": true,
								}}
							/>
							<FormControl.Error>
								{
									store.sourceForm.connector.meta.secretKey
										.errorMessage
								}
							</FormControl.Error>
						</FormControl>
					</View.Item>
				</View>
			</View>
		)
	} else if (store.sourceForm.connector.type === "sdk") {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="sdk">
				<Text variant="body-2" attributes={{ style: { width: "65%" } }}>
					After creating the pipeline, you can go to a jupyter
					notebook on github and learn how to send data with python
					SDK.
				</Text>
			</View>
		)
	} else if (store.sourceForm.connector.type === "webhook") {
		details = (
			<View gap={4} direction="column" paddingTop={6} key="webhook">
				<Text variant="body-2" attributes={{ style: { width: "65%" } }}>
					After the pipeline is created, you will see a Webhook URL
					where you can push events for this pipeline. This is
					compatible with tools like Zapier, Supabase.
				</Text>
			</View>
		)
	}

	return (
		<>
			<ExpandableTabTopPanel
				title="Source"
				onClose={sourceModalStore.closeModal}
				onNextClick={() => {}}
				onPrevClick={() => {}}
				validate={store.isSourceFormValid}
				prevButtonName=""
				nextButtonName=""
				withClose
			/>
			<View paddingTop={4}>
				<View>
					<View
						paddingBottom={6}
						direction="row"
						gap={3}
						align="center"
					>
						<Text variant="body-2">
							Select a managed connector or choose SDK to send
							data using python{" "}
							<Link
								href="https://docs.glassflow.dev/concepts/data-source"
								attributes={{ target: "_blank" }}
								variant="plain"
								icon={Info}
							>
								Learn more
							</Link>
						</Text>
					</View>
					<View direction="row" align="stretch" gap={4}>
						<RadioGroup
							name="source"
							onChange={(event) => {
								store.sourceForm.setType(
									event.value as
										| "sdk"
										| "webhook"
										| "amazon_sqs"
										| "google_pubsub",
								)
							}}
						>
							{SourceOptions.map((option) => (
								<CardRadio
									key={option.value}
									isSelected={
										store.sourceForm.connector.type ===
										option.value
									}
									value={option.value}
									name={option.name}
									badge={option.badge}
									icon={option.icon}
								/>
							))}
						</RadioGroup>
					</View>
					{details}
				</View>
			</View>
		</>
	)
})
