import { cast, flow, types } from "mobx-state-tree"

import { SpaceModel, api } from "#api"
import { apiPageSize } from "#constants"
import { router } from "#router"

export const SpacesPage = types
	.model({
		spaces: types.array(SpaceModel),
		totalAmount: types.optional(types.number, 0),
		currentPage: types.optional(types.number, 1),
		isFetchingSpaces: types.optional(types.boolean, false),
	})
	.views((self) => {
		const hasSpaces = () => {
			return !self.isFetchingSpaces && self.spaces.length > 0
		}

		const amountOfPages = () => {
			return Math.ceil(self.totalAmount / apiPageSize)
		}

		const needsPagination = () => {
			return amountOfPages() > 1
		}

		return { hasSpaces, amountOfPages, needsPagination }
	})
	.actions((self) => {
		const getSpacesPage = flow(function* (options: {
			page: number
			size?: number
			hideFetching?: boolean
		}) {
			if (!options.hideFetching) {
				self.isFetchingSpaces = true
			}

			let page = options.page

			let result: Awaited<ReturnType<typeof api.listSpaces>> =
				yield api.listSpaces({
					queries: {
						page: options.page,
						page_size: options.size ?? apiPageSize,
					},
				})

			// Current page has no spaces, but there are spaces overall
			if (result.spaces.length === 0 && result.total_amount > 0) {
				page = Math.ceil(result.total_amount / apiPageSize)

				result = yield api.listSpaces({
					queries: {
						page,
						page_size: options.size ?? apiPageSize,
					},
				})
			}

			history.replaceState(null, "", `${router.Spaces()}?page=${page}`)

			self.currentPage = page
			self.spaces = cast(result.spaces)
			self.totalAmount = result.total_amount

			self.isFetchingSpaces = false
		})

		return {
			getSpacesPage,
		}
	})

export const spacesPageStore = SpacesPage.create()
