import { observer } from "mobx-react"
import { AlertCircle, Check } from "react-feather"
import {
	ActionBar,
	Alert,
	Button,
	FileUpload,
	FormControl,
	Link,
	Modal,
	View,
	useToast,
} from "reshaped"

import { modalsStore } from "#modals"

export const UploadFunctionFileModal = observer(() => {
	const toast = useToast()
	let alert = null

	const functionFileStore = modalsStore.uploadFunctionFile

	const updateFunctionFile = async () => {
		if (await functionFileStore.upload()) {
			const id = toast.show({
				color: "neutral",
				timeout: 5000,
				icon: Check,
				title: "Function File updated.",
				actionsSlot: (
					<Button
						variant="faded"
						color="inherit"
						onClick={() => toast.hide(id)}
					>
						Dismiss
					</Button>
				),
			})
		}
	}

	if (functionFileStore.error) {
		alert = (
			<Alert
				color="critical"
				icon={AlertCircle}
				title="Something went wrong. Please try again."
			/>
		)
	}

	return (
		<Modal
			className="modal"
			active={functionFileStore.isOpen}
			padding={0}
			size="500px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Upload New Function File</Modal.Title>

				<FormControl hasError={functionFileStore.form.file.hasError}>
					<FileUpload
						name="file"
						onChange={(event) => {
							functionFileStore.form.setFile(event.value[0])
						}}
					>
						{functionFileStore.form.file.value ? (
							functionFileStore.form.file.value.name
						) : (
							<>
								Drop file to attach, or{" "}
								<FileUpload.Trigger>
									<Link variant="plain">browse</Link>
								</FileUpload.Trigger>
							</>
						)}
					</FileUpload>
					<FormControl.Error>
						{functionFileStore.form.file.errorMessage}
					</FormControl.Error>
				</FormControl>

				{alert}
			</View>

			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						disabled={functionFileStore.isUploading}
						color="neutral"
						onClick={() => {
							functionFileStore.closeModal()
						}}
					>
						Cancel
					</Button>
					<Button
						variant="faded"
						color="positive"
						onClick={() => {
							updateFunctionFile()
						}}
						loading={functionFileStore.isUploading}
					>
						Upload Function File
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})
