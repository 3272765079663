import { observer } from "mobx-react"
import { useEffect } from "preact/hooks"
import { router } from "#router"
import { pipelinesPageStore } from "../Pipelines/page.model"

export const Root = observer(() => {
	useEffect(() => {
		const checkDataLoaded = () => {
			if (pipelinesPageStore.totalAmount > 0) {
				router.push("PipelinesList")
			} else {
				// Case >> Initally load the app first time
				if (
					!pipelinesPageStore.isFetchingPipelines &&
					pipelinesPageStore.totalAmount > 0
				) {
					router.push("PipelinesList")
				} else if (!pipelinesPageStore.isFetchingPipelines) {
					router.push("Onboarding")
				} else {
					setTimeout(checkDataLoaded, 50) // Check again after 50ms
				}
			}
		}
		checkDataLoaded()
	}, [])

	return null
})
