import { types } from "mobx-state-tree"

let updateCallback: () => Promise<void>

export const CreatePipelineModalModel = types
	.model("PipelineCreateModal", {
		isOpen: types.optional(types.boolean, false),
	})
	.actions((self) => {
		return {
			openModal(callback: () => Promise<void> = async () => {}) {
				updateCallback = callback
				self.isOpen = true
			},
			closeModal(discard: boolean) {
				self.isOpen = false
			},
		}
	})
