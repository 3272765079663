import { observer } from "mobx-react"
import { Modal } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import css from "../../components/PipelineCreation/styles.module.css"
import { pipelineCreatePageStore } from "../../pages/PipelineCreate/page.model"
import { DataSource } from "../../pages/PipelineDetail/tabs/dataSource"

export const PipelineSourceModal = observer(() => {
	const sourceModalStore = modalsStore.pipelineSource
	const store = pipelineCreatePageStore
	const location = useLocation()

	useEffect(() => {
		if (!location.raw.path.includes("pipelines/")) {
			sourceModalStore.closeModal()
		}
	}, [location.raw.path, sourceModalStore.closeModal])

	return (
		<Modal
			className="modal"
			active={sourceModalStore.isOpen}
			padding={6}
			size="1024px"
			onClose={async () => {
				if (await store.isSourceFormValid()) {
					sourceModalStore.closeModal()
				}
			}}
			overlayClassName={css.overlay0}
			blurredOverlay
		>
			<DataSource isEdit={true} onDiscard={() => {}} onSave={() => {}} />
		</Modal>
	)
})
