import { observer } from "mobx-react"
import { useEffect, useRef } from "preact/hooks"
import { View } from "reshaped"
import { SinkDataRow } from "../../../components/SinkDataRow/component"
import { demoPipelineDetailPageStore } from "../page.model"
import css from "./GlassFlowSink.module.css"

export const GlassFlowSink = observer(() => {
	const store = demoPipelineDetailPageStore
	const containerRef = useRef<HTMLDivElement | null>(null)

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		store.onboardingTips.yourFirstPipeline &&
			store.glassFlowSink.data.length === 0 &&
			store.glassFlowSink.getGlassFlowSink(store.pipeline?.id || "", 20)

		const interval = setInterval(
			() =>
				store.glassFlowSink.getGlassFlowSink(
					store.pipeline?.id || "",
					1,
				),
			1000,
		)

		return () => clearInterval(interval)
	}, [store.onboardingTips.yourFirstPipeline])

	return (
		<View className={css.overflowWrapper}>
			<View className={css.wrapper} attributes={{ ref: containerRef }}>
				{store.glassFlowSink.data.map((data) => (
					<SinkDataRow
						onClick={() => {
							if (
								containerRef.current &&
								"scrollTop" in containerRef.current &&
								containerRef.current.scrollTop === 0
							) {
								containerRef.current.scrollTop = 1
							}
						}}
						key={data.date}
						text={data.text}
					/>
				))}
			</View>
		</View>
	)
})
