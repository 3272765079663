import { getLocation } from "@swan-io/chicane"
import * as Fathom from "fathom-client"
import { types } from "mobx-state-tree"

import { auth0Client } from "#app"

export const UnauthenticatedPage = types.model({}).actions((self) => {
	return {
		signUp() {
			Fathom.trackEvent("SignUp Started")

			auth0Client.loginWithRedirect({
				appState: {
					location: getLocation().toString(),
				},
				authorizationParams: {
					redirect_uri: `${location.origin}#signUpSuccess`,
				},
			})
		},

		login() {
			Fathom.trackEvent("Login Started")

			auth0Client.loginWithRedirect({
				appState: {
					location: getLocation().toString(),
				},
				authorizationParams: {
					redirect_uri: `${location.origin}#loginSuccess`,
				},
			})
		},
	}
})

export const unauthenticatedPageStore = UnauthenticatedPage.create({})
