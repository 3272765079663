import classNames from "classnames"
import { observer } from "mobx-react"
import { Suspense, createRef, useEffect, useState } from "preact/compat"
import { Check, Globe, Info, Upload } from "react-feather"
import {
	Accordion,
	Button,
	FormControl,
	HiddenVisually,
	Tabs,
	Text,
	Tooltip,
	View,
	useTheme,
	useToast,
} from "reshaped"

import { api } from "#api"
import { AceEditor } from "#components/AceEditor"
import { modalsStore } from "#modals"

import type { EnvironmentVariable } from "../../../modals/EditEnvironmentVariables/modal.model"
import { pipelineDetailPageStore } from "../page.model"
import css from "./TransformerTest.module.css"

function isValidJson(str: string) {
	try {
		JSON.parse(str)
		return true
	} catch (e) {
		return false
	}
}

interface TransformerProps {
	isTransformStepComplete: boolean
	isEdit: boolean
	onDiscard: () => void
	onSave: () => void
	onContinue: () => void
}

export const TransformerTest: React.FC<TransformerProps> = observer(
	({ isTransformStepComplete, isEdit, onDiscard, onSave, onContinue }) => {
		const { colorMode } = useTheme()
		const toast = useToast()
		const fileInput = createRef<HTMLInputElement>()
		const [enableContinueBtn, setEnableContinueBtn] = useState(
			isTransformStepComplete,
		)

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			if (
				isTransformStepComplete === false &&
				pipelineDetailPageStore.transformer.form.handler.value !== ""
			) {
				const str =
					pipelineDetailPageStore.transformer.form.handler.value
				if (str.slice(-5) === "data\n") {
					pipelineDetailPageStore.transformer.form.setHandler(
						`${pipelineDetailPageStore.transformer.form.handler.value} `,
					)
				}
			}
		}, [pipelineDetailPageStore.transformer.form.handler.value])

		const testEventButton = (
			<Button
				variant="solid"
				color="positive"
				onClick={() => {
					if (
						!isValidJson(
							pipelineDetailPageStore.transformer.form.testEvent
								.value,
						)
					) {
						pipelineDetailPageStore.transformer.form.setTestEventErrorMesage(
							"A valid JSON is required",
						)
						pipelineDetailPageStore.transformer.form.setTestEventResponse(
							"",
						)
						return
					}
					pipelineDetailPageStore.callTestFunction(
						async (response) => {
							const responseString = JSON.stringify(
								response,
								null,
								2,
							) // Pretty-print with 2 spaces
							pipelineDetailPageStore.transformer.form.setTestEventResponse(
								responseString,
							)
						},
						JSON.parse(
							pipelineDetailPageStore.transformer.form.testEvent
								.value,
						),
					)
				}}
				disabled={
					!pipelineDetailPageStore.transformer.form.hasEventChanges ||
					pipelineDetailPageStore.transformer.form.hasChanges
				}
				loading={pipelineDetailPageStore.isTestingEvent}
			>
				Test Sample Event
			</Button>
		)

		const envVariable = (
			<Button
				color="neutral"
				endIcon={Globe}
				onClick={() => {
					modalsStore.editEnvironmentVariables.openModal(
						async (environmentVariables) => {
							if (pipelineDetailPageStore.pipeline) {
								let envs = await api.patchFunction(
									{
										environments:
											environmentVariables.filter(
												({ name, value }) => {
													if (
														name === "" ||
														value === ""
													) {
														return false
													} else {
														return true
													}
												},
											),
									},
									{
										params: {
											pipeline_id:
												pipelineDetailPageStore.pipeline
													?.id,
										},
									},
								)
								const envVar = (envs.environments ??
									[]) as EnvironmentVariable[]
								pipelineDetailPageStore.setEnvVariables(envVar)
							}
						},
					)
				}}
			>
				Environment Variables
			</Button>
		)

		const saveBtn = (
			<Button
				variant="solid"
				color="primary"
				onClick={async () => {
					await pipelineDetailPageStore.saveTransformer(async () => {
						const id = toast.show({
							color: "neutral",
							timeout: 5000,
							icon: Check,
							title: "Transformer saved.",
							actionsSlot: (
								<Button
									variant="faded"
									color="inherit"
									onClick={() => toast.hide(id)}
								>
									Dismiss
								</Button>
							),
						})
						setEnableContinueBtn(true)
					})
					onSave()
				}}
				disabled={!pipelineDetailPageStore.transformer.form.hasChanges}
				loading={pipelineDetailPageStore.isSaving}
			>
				Save Changes
			</Button>
		)

		const discardAndSaveBtn = (
			<View grow align="end">
				<View direction="row" gap={3}>
					{isEdit && (
						<>
							<Button
								color="neutral"
								onClick={() => {
									pipelineDetailPageStore.transformer.form.setHandler(
										pipelineDetailPageStore.transformer.form
											.currentHandler,
									)
									onDiscard()
								}}
							>
								Discard
							</Button>
							{saveBtn}
						</>
					)}
				</View>
			</View>
		)

		const continueButton = (
			<Button
				color="primary"
				disabled={!enableContinueBtn}
				onClick={onContinue}
			>
				Continue
			</Button>
		)

		const TransformEditor = (
			<FormControl
				hasError={
					pipelineDetailPageStore.transformer.form.handler.hasError
				}
			>
				<Tabs>
					<View
						direction="row"
						justify="space-between"
						align="center"
					>
						<Tabs.List>
							<Tabs.Item value="handler">handler.py</Tabs.Item>
							<Tabs.Item value="requirements">
								requirements.txt
							</Tabs.Item>
						</Tabs.List>
						<View direction="row" gap={3}>
							{envVariable}
						</View>
					</View>

					<Tabs.Panel value="handler">
						<View minHeight="300px" paddingTop={3}>
							<Suspense fallback={null}>
								<AceEditor
									style={{
										border: "0px",
										background: "#16181E",
										zIndex: 0,
									}}
									onLoad={(editorInstance) => {
										editorInstance.setOption(
											"wrap",
											pipelineDetailPageStore.transformer
												.editorSettings.softWrap,
										)

										editorInstance.setOption(
											"showInvisibles",
											pipelineDetailPageStore.transformer
												.editorSettings.showInvisibles,
										)
									}}
									className={classNames({
										[css.editor]: true,
										[css.editorError]:
											pipelineDetailPageStore.transformer
												.form.handler.hasError,
									})}
									mode="python"
									theme={
										colorMode === "dark"
											? "dracula"
											: "xcode"
									}
									onChange={(value) => {
										pipelineDetailPageStore.transformer.form.setHandler(
											value,
										)
									}}
									value={
										pipelineDetailPageStore.transformer.form
											.handler.value
									}
									lineHeight="1.65"
									width="100%"
									height="400px"
									fontSize={14}
									showGutter
									tabSize={4}
									readOnly={pipelineDetailPageStore.isSaving}
									enableBasicAutocompletion
									enableLiveAutocompletion
									showPrintMargin={false}
									editorProps={{ $blockScrolling: true }}
								/>
							</Suspense>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<FormControl.Error>
									{
										pipelineDetailPageStore.transformer.form
											.handler.errorMessage
									}
								</FormControl.Error>
							</div>
						</View>
					</Tabs.Panel>
					<Tabs.Panel value="requirements">
						<View minHeight="300px" paddingTop={3}>
							<Suspense fallback={null}>
								<AceEditor
									style={{
										border: "0px",
										background: "#16181E",
										zIndex: 0,
									}}
									onLoad={(editorInstance) => {
										editorInstance.setOption(
											"wrap",
											pipelineDetailPageStore.transformer
												.editorSettings.softWrap,
										)

										editorInstance.setOption(
											"showInvisibles",
											pipelineDetailPageStore.transformer
												.editorSettings.showInvisibles,
										)
									}}
									className={classNames({
										[css.editor]: true,
										[css.editorError]:
											pipelineDetailPageStore.transformer
												.form.requirements.hasError,
									})}
									mode="python"
									theme={
										colorMode === "dark"
											? "dracula"
											: "xcode"
									}
									onChange={(value) => {
										pipelineDetailPageStore.transformer.form.setRequirements(
											value,
										)
									}}
									value={
										pipelineDetailPageStore.transformer.form
											.requirements.value
									}
									lineHeight="1.65"
									width="100%"
									height="400px"
									fontSize={14}
									showGutter
									tabSize={4}
									readOnly={pipelineDetailPageStore.isSaving}
									enableBasicAutocompletion
									enableLiveAutocompletion
									showPrintMargin={false}
									editorProps={{ $blockScrolling: true }}
								/>
							</Suspense>

							<FormControl.Error>
								{
									pipelineDetailPageStore.transformer.form
										.handler.errorMessage
								}
							</FormControl.Error>
						</View>
					</Tabs.Panel>
				</Tabs>
				<View direction="row" gap={3} insetTop={1}>
					<HiddenVisually>
						<input
							type="file"
							ref={fileInput}
							accept=".py"
							onChange={() => {
								const files = fileInput.current?.files

								if (files?.length) {
									const file = files[0]

									let reader = new FileReader()

									reader.addEventListener("load", (event) => {
										if (
											typeof event.target?.result ===
											"string"
										) {
											pipelineDetailPageStore.transformer.form.setHandler(
												event.target.result,
											)
										}
									})

									reader.readAsText(file, "UTF-8")
								}
							}}
						/>
					</HiddenVisually>
					<div style={{ paddingTop: "10px", paddingBottom: "12px" }}>
						<Button
							variant="outline"
							color="neutral"
							onClick={() => {
								fileInput.current?.click()
							}}
							endIcon={Upload}
						>
							Upload Handler File
						</Button>
					</div>
				</View>
			</FormControl>
		)

		const stackTrace =
			pipelineDetailPageStore.transformer.form.testEventResponse?.value?.replace(
				/\\n/g,
				"\n",
			)
		const TestTransformEditor = (
			<FormControl
				hasError={
					pipelineDetailPageStore.transformer.form.testEvent.hasError
				}
			>
				{(isTransformStepComplete || enableContinueBtn) && (
					<>
						<div
							className={isEdit ? "" : css.card}
							style={{
								paddingBottom: "relative",
							}}
						>
							<Accordion
								className={css.grid_bottom}
								iconSize={6}
								iconPosition="start"
							>
								<Accordion.Trigger>
									<Text variant={"title-4"} weight={"medium"}>
										Test Transform Step
									</Text>
								</Accordion.Trigger>
								<Accordion.Content>
									<View
										position="relative"
										className={css.container}
									>
										<View minHeight="300px" paddingTop={3}>
											<div style={{ display: "flex" }}>
												<div>Sample event</div>
												<div
													style={{
														marginLeft: "450px",
													}}
												>
													Output
												</div>
											</div>
											<div
												disabled={
													!pipelineDetailPageStore
														.transformer.form
														.hasChanges
												}
												style={{
													marginTop: "1rem",
													display: "flex",
													justifyContent:
														"space-between",
													flexDirection: "row",
													height: "87%",
												}}
											>
												<Suspense fallback={null}>
													<div
														style={{
															width: "49%",
															height: "100%",
														}}
													>
														<AceEditor
															onLoad={(
																editorInstance,
															) => {
																editorInstance.setOption(
																	"wrap",
																	pipelineDetailPageStore
																		.transformer
																		.editorSettings
																		.softWrap,
																)

																editorInstance.setOption(
																	"showInvisibles",
																	pipelineDetailPageStore
																		.transformer
																		.editorSettings
																		.showInvisibles,
																)
															}}
															style={{
																border: "0px",
																background:
																	"#16181E",
																zIndex: 0,
															}}
															className={classNames(
																{
																	[css.editor]: true,
																	[css.editorError]:
																		pipelineDetailPageStore
																			.transformer
																			.form
																			.testEvent
																			.hasError,
																},
															)}
															mode="python"
															theme={
																colorMode ===
																"dark"
																	? "dracula"
																	: "xcode"
															}
															onChange={(
																value,
															) => {
																pipelineDetailPageStore.transformer.form.setTestEvent(
																	value,
																)
															}}
															value={
																pipelineDetailPageStore
																	.transformer
																	.form
																	.testEvent
																	.value
															}
															lineHeight="1.65"
															width="100%"
															height="360px"
															fontSize={14}
															showGutter
															tabSize={4}
															readOnly={
																pipelineDetailPageStore.isSaving
															}
															enableBasicAutocompletion
															enableLiveAutocompletion
															showPrintMargin={
																false
															}
															editorProps={{
																$blockScrolling: true,
															}}
														/>
													</div>
												</Suspense>
												<Suspense fallback={null}>
													<div
														style={{
															width: "49%",
															height: "100%",
														}}
													>
														<AceEditor
															style={{
																border: "0px",
																background:
																	"#16181E",
																zIndex: 0,
															}}
															onLoad={(
																editorInstance,
															) => {
																editorInstance.setOption(
																	"wrap",
																	pipelineDetailPageStore
																		.transformer
																		.editorSettings
																		.softWrap,
																)
																editorInstance.setHighlightActiveLine(
																	false,
																)
																editorInstance.setHighlightGutterLine(
																	false,
																)
																editorInstance.clearSelection()
															}}
															className={classNames(
																{
																	[css.editor]: true,
																	[css.editorError]:
																		pipelineDetailPageStore
																			.transformer
																			.form
																			.handler
																			.hasError,
																},
															)}
															mode="python"
															theme={
																colorMode ===
																"dark"
																	? "dracula"
																	: "xcode"
															}
															value={stackTrace}
															lineHeight="1.65"
															width="100%"
															height="360px"
															fontSize={14}
															showGutter
															tabSize={4}
															readOnly={true}
															showPrintMargin={
																false
															}
														/>
													</div>
												</Suspense>
											</div>
											<div
												style={{
													display: "inline-flex",
													justifyContent: "flex-end",
													justifySelf: "flex-end",
													width: "100%",
												}}
											>
												<FormControl.Error>
													{
														pipelineDetailPageStore
															.transformer.form
															.testEvent
															.errorMessage
													}
												</FormControl.Error>
											</div>
										</View>
									</View>
									<div>{testEventButton}</div>
								</Accordion.Content>
							</Accordion>
						</div>
						{!isEdit && <div style={{ paddingBottom: "20px" }} />}
					</>
				)}
			</FormControl>
		)

		return (
			<>
				<View>
					<View direction="row" align="center">
						<Text variant={"title-5"}>
							{!isEdit
								? "Create and Test Transform Function"
								: "Transform"}
						</Text>
						<Tooltip
							text={
								<>
									Read more about the data transformations
									that you can do with GlassFlow. <br />
									<a
										href="https://www.glassflow.dev/docs/concepts/data-transformation"
										target="_blank"
										rel="noopener noreferrer"
										style={{
											color: "#FFA24B",
											textDecoration: "none",
										}}
									>
										Learn more
									</a>
								</>
							}
							position="end"
						>
							{(attributes) => (
								<Button
									variant="ghost"
									icon={Info}
									attributes={attributes}
								/>
							)}
						</Tooltip>
						{discardAndSaveBtn}
					</View>
					<div style={{ marginTop: "4px" }} />
					{TransformEditor}
					<div style={{ marginTop: "8px" }} />
					{TestTransformEditor}
				</View>
				{!isEdit && (
					<div
						style={{
							display: "flex",
							gap: "12px",
							paddingTop: "8px",
						}}
					>
						{saveBtn}
						{continueButton}
					</div>
				)}
			</>
		)
	},
)
