export enum PipelineCreateType {
	EmailEncryption = "EmailEncryption",
	Draft = "Draft",
	Blank = "Blank",
}

export enum NavigationSectionsType {
	Create = "Create",
	Pipelines = "Pipelines",
	Spaces = "Spaces",
}

export enum PipelineDetailSectionsType {
	Transform = "Transform",
	Source = "Source",
	Sink = "Sink",
	Deploy = "Deploy",
	None = "None",
}

export enum DataGeneratorOptionsType {
	name = "name",
	text = "text",
	address = "address",
	phone_number = "phone_number",
	job = "job",
	company = "company",
	country = "country",
	city = "city",
	administrative_unit = "administrative_unit",
	zipcode = "zipcode",
	user_name = "user_name",
	password = "password",
	ssn = "ssn",
	ipv4 = "ipv4",
	url = "url",
	uuid4 = "uuid4",
	boolean = "boolean",
	currency_name = "currency_name",
	color_name = "color_name",
	company_email = "email",
}
