import { flow, types } from "mobx-state-tree"
import { api } from "#api"
import { appId, appStore } from "#app"
import { metricsApi } from "#metrics"

export const QuestionModel = types
	.model("Question", {
		question: types.string,
		answers: types.array(types.string),
		selectedAnswer: types.maybeNull(types.string),
	})
	.actions((self) => ({
		selectAnswer(answer: string) {
			self.selectedAnswer = answer
		},
	}))

export const QuestionnaireStore = types
	.model("QuestionnaireStore", {
		questions: types.array(QuestionModel),
		activeQuestionIndex: types.optional(types.number, 0),
		isSecondQuestionAnswersShown: types.optional(types.boolean, false),
	})
	.actions((self) => {
		const setActiveQuestionIndex = (index: number) => {
			self.activeQuestionIndex = index
		}

		const showSecondQuestionAnswers = (isShown: boolean) => {
			self.isSecondQuestionAnswersShown = isShown
		}

		const saveAnswers = flow(function* () {
			if (appStore.auth.isAuthenticated) {
				const questionsData = self.questions
					.filter((item) => item.selectedAnswer)
					.map((item, index) => ({
						question: item.question,
						answer: item.selectedAnswer,
						order: index,
					}))

				yield api.SaveUserExternalSettings({
					...appStore.auth.profile.external_settings,
					questions: true,
				})
				if (questionsData.length > 0) {
					yield metricsApi.postMetricEvents(
						[
							{
								name: "onboarding_questionnaire",
								created_at: new Date().toISOString(),
								data: {
									user_id: appStore.auth.profile.id,
									email: appStore.auth.profile.email,
									timestamp: new Date().toISOString(),
									questions: questionsData,
								},
								metadata: {
									source: appId,
								},
							},
						],
						{},
					)
				}
			}
		})

		const skipAnswers = flow(function* () {
			if (appStore.auth.isAuthenticated) {
				yield api.SaveUserExternalSettings({
					...appStore.auth.profile.external_settings,
					questions: true,
				})
			}
		})

		return {
			setActiveQuestionIndex,
			showSecondQuestionAnswers,
			saveAnswers,
			skipAnswers,
		}
	})

export const questionnaireStore = QuestionnaireStore.create({
	questions: [
		{
			question: "What is your role",
			answers: [
				"Data Engineer",
				"Data Analyst",
				"Data Scientist",
				"Data Consultant",
				"Data Lead / Manager",
				"Founder",
				"Other",
			],
			selectedAnswer: null,
		},
		{
			question: "Do you have a use case?",
			answers: [
				"Event-Driven Analytics",
				"AI Parsing",
				"Rag Pipeline",
				"Automation",
				"Data Enrichment",
				"Real-Time API Call",
				"Other",
			],
			selectedAnswer: null,
		},
		{
			question: "What is your experience with data streaming?",
			answers: [
				"No Experience",
				"Tried Before",
				"Actively Using / Used",
				"Expert",
			],
			selectedAnswer: null,
		},
	],
})
