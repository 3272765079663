import { cast, flow, types } from "mobx-state-tree"

import { PipelineModel, api } from "#api"
import { apiPageSize } from "#constants"
import { router } from "#router"

export const PipelinesPage = types
	.model({
		pipelines: types.array(PipelineModel),
		totalAmount: types.optional(types.number, 0),
		currentPage: types.optional(types.number, 1),
		isFetchingPipelines: types.optional(types.boolean, false),
	})
	.views((self) => {
		const hasPipelines = () => {
			return !self.isFetchingPipelines && self.pipelines.length > 0
		}

		const amountOfPages = () => {
			return Math.ceil(self.totalAmount / apiPageSize)
		}

		const needsPagination = () => {
			return amountOfPages() > 1
		}

		return {
			hasPipelines,
			amountOfPages,
			needsPagination,
		}
	})
	.actions((self) => {
		const getPipelinesPage = flow(function* (options: {
			page: number
			pageSize?: number
			hideFetching?: boolean
		}) {
			if (!options.hideFetching) {
				self.isFetchingPipelines = true
			}

			let page = options.page

			let result: Awaited<ReturnType<typeof api.listPipelines>> =
				yield api.listPipelines({
					queries: {
						page: options.page,
						page_size: options.pageSize ?? apiPageSize,
					},
				})

			// Current page has no pipelines, but there are pipelines overall
			if (result.pipelines.length === 0 && result.total_amount > 0) {
				page = Math.ceil(result.total_amount / apiPageSize)

				result = yield api.listPipelines({
					queries: {
						page,
						page_size: options.pageSize ?? apiPageSize,
					},
				})
			}

			history.replaceState(
				null,
				"",
				`${router.PipelinesList()}?page=${page}`,
			)

			self.currentPage = page
			self.pipelines = cast(result.pipelines)
			self.totalAmount = result.total_amount

			self.isFetchingPipelines = false
		})

		return {
			getPipelinesPage,
		}
	})

export const pipelinesPageStore = PipelinesPage.create()
